import { generateClientId } from '../store/modules/utils'

export class Referral {
  static INSURANCE_TYPE = Object.freeze({
    PROVIDED_BELOW: 'providedbelow',
    CALL_OFFICE: 'calloffice',
    CALL_PATIENT: 'callpatient',
    NO_INSURANCE: 'noinsurance',
  })

  // Mapping of enum values to display strings
  static INSURANCE_TYPE_DISPLAY = Object.freeze({
    [Referral.INSURANCE_TYPE.PROVIDED_BELOW]: 'Provided Below',
    [Referral.INSURANCE_TYPE.CALL_OFFICE]: 'Call Office',
    [Referral.INSURANCE_TYPE.CALL_PATIENT]: 'Call Patient',
    [Referral.INSURANCE_TYPE.NO_INSURANCE]: 'No Insurance',
  })

  // Enum for values (used in code references)
  static CONTACT_METHOD = Object.freeze({
    NO_PREFERENCE: 'any',
    PHONE: 'phone',
    EMAIL: 'email',
  })

  // Mapping of enum values to display strings
  static CONTACT_METHOD_DISPLAY = Object.freeze({
    [Referral.CONTACT_METHOD.NO_PREFERENCE]: 'No Preference',
    [Referral.CONTACT_METHOD.PHONE]: 'Phone',
    [Referral.CONTACT_METHOD.EMAIL]: 'Email',
  })

  // Enum for values (used in code references)
  static EMAIL_STATUS = Object.freeze({
    UNSENT: 'unsent',
    SENT: 'sent',
    BOUNCED: 'bounced',
    FAILED: 'failed',
    DELIVERED: 'delivered',
    OPENED: 'opened',
    CLICKED: 'clicked',
  })

  // Mapping of enum values to display strings
  static EMAIL_STATUS_DISPLAY = Object.freeze({
    [Referral.EMAIL_STATUS.UNSENT]: 'Unsent',
    [Referral.EMAIL_STATUS.SENT]: 'Sent',
    [Referral.EMAIL_STATUS.BOUNCED]: 'Bounced',
    [Referral.EMAIL_STATUS.FAILED]: 'Failed',
    [Referral.EMAIL_STATUS.DELIVERED]: 'Delivered',
    [Referral.EMAIL_STATUS.OPENED]: 'Opened',
    [Referral.EMAIL_STATUS.CLICKED]: 'Clicked',
  })

  static DOCTOR_DEFAULT = 'Any (First Available)'
  static CONTACT_METHOD_DEFAULT = Referral.CONTACT_METHOD.NO_PREFERENCE

  static _writableFields = [
    'patient_firstname',
    'patient_middlename',
    'patient_lastname',
    'patient_phone', // Conditionally writable based on the logic in get_fields
    'patient_dob',
    'patient_email',
    'guardian_name',
    'preferred_contact_method',
    'referring_doctor',
    'referring_doctor_id',
    'comments',
    'reasons',
    'teeth',
    'type',
    'cancelled_time',
    'completed_time',
    'client_version',
    'is_important',
    'to_practice_id',
    'scheduled_time',
    'requested_scheduled_time',
    'doctor',
    'sent_time',
    'archived_from_time',
    'archived_to_time',
    'snooze_until_from_time',
    'snooze_until_to_time',
    'patient_insurance_network',
    'patient_insurance_membership',
    'patient_insurance_subscriber',
    'patient_insurance_dob',
    'patient_insurance_network_secondary',
    'patient_insurance_membership_secondary',
    'patient_insurance_subscriber_secondary',
    'patient_insurance_dob_secondary',
    'insurance_information',
    'requires_followup',
    'is_patient_opted_in',
    'is_marked_scheduled',
    'followup_completed_time',
    'receiver_viewed_at_time',
    'request_send',
    'patient_internal_id_sender',
  ]

  constructor({ id = null, referring_doctor_id = null } = {}) {
    this.id = id || generateClientId()
    this._id = this.id
    this._new = true
    this.patient_firstname = null
    this.patient_middlename = null
    this.patient_lastname = null
    this.patient_phone = null
    this.patient_email = null
    this.patient_dob = null
    this.patient_insurance_network = null
    this.patient_insurance_subscriber = null
    this.patient_insurance_membership = null
    this.patient_insurance_dob = null
    this.patient_insurance_network_secondary = null
    this.patient_insurance_subscriber_secondary = null
    this.patient_insurance_membership_secondary = null
    this.patient_insurance_dob_secondary = null
    this.guardian_name = null

    this.preferred_contact_method = Referral.CONTACT_METHOD_DEFAULT

    this.referring_doctor = {}
    this.referring_doctor_id = referring_doctor_id
    this.to_practice = null
    this.to_practice_id = null
    this.doctor = Referral.DOCTOR_DEFAULT
    this.reasons = []
    this.teeth = []
    this.attachments = []
    this.insurance_information = null

    this.is_patient_opted_in = true
    this.is_important = false
    this.is_marked_scheduled = false
    this.requires_followup = false

    this.sent_time = null
    this.cancelled_time = null
    this.completed_time = null
    this.scheduled_time = null
    this.requested_scheduled_time = null
    this.followup_completed_time = null
    this.client_version = Date.now()
    this.patient_called_on = null

    this.archived_from_time = null
    this.archived_to_time = null
    this.snooze_until_from_time = null
    this.snooze_until_to_time = null

    this.last_email_status = Referral.EMAIL_STATUS.UNSENT
    this.last_email_status_time = null
    this.audit = []

    this.patient_internal_id_sender = null
  }
}

export function createReferral(data) {
  return Object.freeze(new Referral(data))
}

export function prepReferralForSave(referral) {
  const filteredReferral = {}

  Referral._writableFields.forEach((field) => {
    if (referral[field] !== undefined) {
      filteredReferral[field] = referral[field]
    }
  })
  //assume it's not a new referral if it has updated_at
  if (referral.updated_at) {
    filteredReferral.id = referral._id
    if (referral.id != referral._id) {
      console.info('Referral ID mismatch', referral.id, referral._id)
      filteredReferral.id = referral._id
    }
  }

  return filteredReferral
}

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

export function compareReferral(localReferral, serverReferral) {
  const changes = {}

  // Treat null, false, 0, '', and undefined as equal - loosely
  const isEqual = (value1, value2) => (!value1 && !value2) || value1 == value2

  // Compare only writable fields
  Referral._writableFields.forEach((field) => {
    const localValue = localReferral[field]
    const serverValue = serverReferral[field]

    if (field === 'referring_doctor') {
      if (localValue?.id !== serverValue?.id) {
        changes[field] = {
          local: localValue,
          server: serverValue,
        }
      }
    } else if (Array.isArray(localValue) && Array.isArray(serverValue)) {
      // Compare arrays
      if (!arraysEqual(localValue, serverValue)) {
        changes[field] = {
          local: localValue,
          server: serverValue,
        }
      }
    } else if (!isEqual(localValue, serverValue)) {
      changes[field] = {
        local: localValue,
        server: serverValue,
      }
    }
  })

  return changes
}
