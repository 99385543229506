import store from './store/index'

export const tryGetFriendlyError = async (response, defaultMessage) => {
  try {
    const json = await response.json()
    return json.message || json.detail || json.error
  } catch (e) {}
  return defaultMessage
    ? typeof defaultMessage === 'function'
      ? defaultMessage(response.statusText)
      : defaultMessage
    : response.statusText
}
export default {
  _getHeaders(obj = {}) {
    let headers = new Headers((obj && obj.headers) || {})

    if (!headers.has('Accept')) headers.append('Accept', 'application/json')
    if (!headers.has('Content-Type') && obj.autoAddContentHeader !== false)
      headers.append('Content-Type', 'application/json')

    const token = store.state.auth.token
    if (token && !obj?.removeToken) {
      headers.append('Authorization', `Token ${token}`)
      if (obj.practiceId || store.state.auth.currentPracticeId)
        headers.append('X-REFERA-PRACTICE-ID', obj.practiceId || store.state.auth.currentPracticeId)
    }

    return headers
  },

  get(url, obj = {}) {
    return this._authorizedJsonRequest(url, 'GET', null, obj)
  },

  patch(url, data = {}) {
    return this._authorizedJsonRequest(url, 'PATCH', data)
  },

  post(url, data = {}, obj = {}) {
    return this._authorizedJsonRequest(url, 'POST', data, obj)
  },

  put(url, data = {}, obj = {}) {
    return this._authorizedJsonRequest(url, 'PUT', data, obj)
  },

  delete(url, data = {}) {
    return this._authorizedJsonRequest(url, 'DELETE', data)
  },

  _authorizedJsonRequest(url, method, data = {}, obj = {}) {
    let headers = this._getHeaders(obj)
    const currToken = store.state.auth.token
    if (!currToken && !obj?.anonymous && !obj?.removeToken) {
      return Promise.reject(new Error(`No token supplied to _authorizedJsonRequest for ${method}:${url}`, data, obj))
    }
    return fetch(url, {
      method: method,
      headers,
      body: data && JSON.stringify(data),
      ...obj,
    }).then((response) => {
      if (response.status === 401) {
        if (url !== process.env.VUE_APP_API_BASE_URL + '/auth/token/logout/') {
          //Todo: for now assume all 401's require a login
          // later, we may choose to respond with a header and use a refresh token
          store.dispatch('auth/clientLogout')
        }
      }
      const newToken = response.headers.get('X-Refera-Token')
      if (newToken) {
        console.info('Received new token')
        const token = newToken == 'REFRESH_CURRENT_USER' ? currToken : newToken
        store.commit('auth/setToken', token)
        store.dispatch('auth/me', { token })
      }

      return response
    })
  },
}
