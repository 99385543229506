import client from '../http-client'
const _objToParams = Symbol('_objToParams')
export default class RevampRestClient {
  // endpoint = ''

  constructor(endpoint) {
    this.endpoint = `${process.env.VUE_APP_API_BASE_URL}/api/${endpoint}/`
  }

  [_objToParams](o) {
    return typeof o === 'string'
      ? o
      : Object.keys(o)
          .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(o[k])}`)
          .join('&')
  }

  async handleResponse(response) {
    const tryParseJsonErrorInformation = async response => {
      try {
        const text = await response.text()
        return { errors: JSON.parse(text) }
      } catch (err) {
        //Non 200-OK request does not have json
        return response.statusText
      }
    }

    if (!response.ok) throw await tryParseJsonErrorInformation(response)

    return response
  }

  create(model, obj = {}) {
    return client
      .post(this.endpoint, model, obj)
      .then(this.handleResponse)
      .then(response => response.json())
  }

  update(id, model, opt = {}) {
    return client
      .put(`${this.endpoint}${id}/`, model, opt)
      .then(this.handleResponse)
      .then(response => response.json())
  }

  patch(id, model, opt = {}) {
    return client
      .patch(`${this.endpoint}${id}/`, model, opt)
      .then(this.handleResponse)
      .then(response => (opt && typeof opt.json === 'boolean' && !opt.json ? response : response.json()))
  }

  delete(id, opt = {}) {
    return client.delete(`${this.endpoint}${id}/`, opt).then(this.handleResponse)
  }

  save(model, opt = {}) {
    return model.id ? this.update(model.id, model, opt) : this.create(model, opt)
  }

  get(id, opt = {}) {
    id = id + ''
    return client
      .get(`${this.endpoint}${id}${id?.indexOf('?') === -1 ? '/' : ''}`, opt)
      .then(this.handleResponse)
      .then(response => response.json())
  }

  all(query = {}, opt = {}) {
    return client
      .get(`${this.endpoint}?${this[_objToParams](query)}`, opt)
      .then(this.handleResponse)
      .then(response => response.json())
  }
  //acts more like detail(id, path) consider rename
  related(id, model, opt = {}) {
    return client
      .get(`${this.endpoint}${id}/${model}/`, opt)
      .then(this.handleResponse)
      .then(response => response.json())
  }

  saveRelated(modelId, name, model, opt = {}) {
    return client
      .post(`${this.endpoint}${modelId}/${name}/${model.id || ''}`, model, opt)
      .then(this.handleResponse)
      .then(response => (opt && typeof opt.json === 'boolean' && !opt.json ? response : response.json()))
  }

  paged(limit = 10, offset = 0, query = {}) {
    return client
      .get(`${this.endpoint}?limit=${limit}&offset=${offset}&${this[_objToParams](query)}`)
      .then(this.handleResponse)
      .then(response => response.json())
      .then(data => ({
        ...data,
        pageCount: Math.ceil(data.count / limit),
        pageNum: (offset + limit) / limit
      }))
  }
}
