import client from '@/http-client'
import store from '@/store'
const baseUrl = process.env.VUE_APP_API_BASE_URL
const handleResponse = async (response) => {
  const tryParseJsonErrorInformation = async (response) => {
    try {
      const text = await response.text()
      return { errors: JSON.parse(text) }
    } catch (err) {
      //Non 200-OK request does not have json
      return response.statusText
    }
  }

  if (!response.ok) throw await tryParseJsonErrorInformation(response)

  return response
}

export default {
  authEmail(email) {
    return this.postWithoutToken(baseUrl + '/auth-v2/auth/email/', { email })
      .then((response) => {
        if (!response.ok) throw response
        return response
      })
      .then((response) => response.json())
  },
  login(email, token) {
    return this.postWithoutToken(baseUrl + '/auth-v2/auth/token/', {
      email,
      token,
    })
      .then((response) => {
        if (!response.ok) throw response
        return response
      })
      .then((response) => response.json())
  },
  loginWithPassword(credentials) {
    return this.postWithoutToken(baseUrl + '/auth/token/login/', credentials)
      .then((response) => {
        if (!response.ok) throw response
        return response
      })
      .then((response) => response.json())
  },
  inviteLogin(invite_key, referral, opreport) {
    return this.postWithoutToken(baseUrl + '/auth/guest-login/', {
      invite_key,
      referral,
      opreport,
    }).then((response) => {
      if (!response.ok) throw response
      return response
    })
  },
  loginPublicUser() {
    return this.postWithoutToken(baseUrl + '/auth/public/', {})
      .then((response) => {
        if (!response.ok) throw response
        return response
      })
      .then((r) => r.json())
  },
  logout() {
    return client.post(baseUrl + '/auth/token/logout/', {}, { anonymous: true }).then((response) => {
      if (!response.ok) throw response
      return response
    })
  },
  forgotPassword(email) {
    return this.postWithoutToken(baseUrl + '/auth/users/reset_password/', {
      email,
    }).then((response) => {
      if (!response.ok) throw response
      return response
    })
  },
  resendActivation(email) {
    return this.postWithoutToken(baseUrl + '/auth/users/resend_activation/', {
      email,
    }).then((response) => {
      if (!response.ok) throw response
      return response
    })
  },
  changePassword(uid, token, new_password) {
    return this.postWithoutToken(baseUrl + '/auth/users/reset_password_confirm/', { uid, token, new_password }).then(
      (response) => {
        if (!response.ok) throw response
        return response
      }
    )
  },
  activateFromGuest(uid, token, new_password, owner_first_name, owner_last_name) {
    return client
      .post(baseUrl + '/api/users/activate_from_guest/', {
        uid,
        token,
        new_password,
        owner_first_name,
        owner_last_name,
      })
      .then((response) => {
        if (!response.ok) throw response
        return response
      })
  },
  postWithoutToken(url, data) {
    let headers = new Headers()

    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')

    return fetch(url, {
      method: 'POST',
      headers,
      body: data && JSON.stringify(data),
    })
  },
  signup({ email, password, practice, first_name, last_name, is_doctor }) {
    return this.postWithoutToken(baseUrl + '/auth/users/', {
      email,
      password,
      practice,
      first_name,
      last_name,
      is_doctor,
    })
      .then(handleResponse)
      .then((response) => response.json())
  },
  provision({ user, uid, token }) {
    return this.postWithoutToken(`${baseUrl}/api/users/${uid}/provision/${token}/`, user)
      .then(handleResponse)
      .then((response) => response.json())
  },
  activate({ uid, token }) {
    return this.postWithoutToken(baseUrl + '/auth/users/activate/', {
      uid,
      token,
    }).then(handleResponse)
  },
  activatePractice({ pid, token }) {
    return this.postWithoutToken(baseUrl + `/api/practices/${pid}/activate/${token}/`, {}).then(handleResponse)
  },
  me(overrideCache = false) {
    const cachedUser = store.state.auth.user
    return cachedUser && !overrideCache
      ? Promise.resolve(JSON.parse(cachedUser))
      : client
          .get(baseUrl + '/auth/users/me/')
          .then((response) => {
            if (!response.ok) throw response.statusText
            return response
          })
          .then((response) => response.json())
  },
  save(user) {
    return client
      .put(baseUrl + '/auth/users/me/', user)
      .then(handleResponse)
      .then((response) => response.json())
  },
  // todo: obsolete - use vuex state
  token() {
    return store.state.auth.token
  },
  async promotePublicUser(doctor, referral) {
    return client
      .post(baseUrl + '/api/users/promote-public-user/', { ...doctor, referral })
      .then((response) => {
        if (!response.ok) throw response
        return response
      })
      .then((response) => response.json())
  },
  async registerPublicUser(firstName, lastName, officeName, officePhone, timezone, zip, toPracticeId) {
    return this.postWithoutToken(baseUrl + '/api/users/register-public-user/', {
      firstName,
      lastName,
      officeName,
      officePhone,
      timezone,
      zip,
      toPracticeId,
    })
      .then((response) => {
        if (!response.ok) throw response
        return response
      })
      .then((response) => response.json())
  },
}
