import Vue from 'vue'
import helpers from './helpers'

const helpersVue = function(router, store) {
  return new Vue({
    router,
    store,
    methods: {
      ...helpers,
      copyReferral: function(referral) {
        return helpers.copyReferral.call(this, referral, store, router, this.$dialog)
      },
      closeReferral: function(referral, prop, title, value, beforeSave) {
        return helpers.closeReferral.call(this, referral, prop, title, value, beforeSave, this.$dialog)
      },
      completeReferral: function(referral) {
        return helpers.completeReferral.call(this, referral, this.$dialog)
      },
      getMessageText: function(msg) {
        return helpers.getMessageText.call(this, msg, store)
      },
      isSchedulable: function(referral) {
        return helpers.isSchedulable.call(this, referral)
      },
      isArchived: function(referral) {
        return helpers.isArchived.call(this, referral, store)
      },
      handleApiError: function(vue) {
        return data => helpers.handleApiError.call(this, data, vue.$validator, vue.$options)
      },
      tryReturn: () => {
        const retVal = router.currentRoute.query.return
        if (retVal) {
          if (retVal == 'true') router.go(-1)
          else router.push(retVal)
          return true
        }
      }
    }
  })
}

export default {
  install: function(Vue, router, store) {
    Vue.prototype.$helpers = helpersVue(router, store)
  }
}
