import Vue from 'vue'
import 'floating-vue/dist/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
// import BaseLayout from './components/_layout/Base.vue'
// import BaseAnonymousLayout from './components/_layout/BaseAnonymous.vue'
import CardHeader from './components/shared/CardHeader'
// import 'bootstrap'
import moment from 'moment'
import { Validator, install as VeeValidate } from 'vee-validate/dist/vee-validate.minimal.esm.js'
import { required, regex, email } from 'vee-validate/dist/rules.esm.js'
import veeEn from 'vee-validate/dist/locale/en'
import Paginate from 'vuejs-paginate'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import { init as SentryInit, browserTracingIntegration, setTag, captureException } from '@sentry/vue'
import helpers from './helpers-vue'
// import VueTour from './plugins/vue-tour'
import Toasted from 'vue-toasted'
// import VueGtag from 'vue-gtag'
import './plugins/fontawesome'
import WithPremium from '@/plugins/premium'
// import PostHog from './plugins/posthog'
import Device from './plugins/device'

import ContentPlaceholders from '@/components/loaders/ContentPlaceholders.vue'
import ContentPlaceholdersHeading from '@/components/loaders/ContentPlaceholdersHeading.vue'
import ContentPlaceholdersImg from '@/components/loaders/ContentPlaceholdersImg.vue'
import ContentPlaceholdersText from '@/components/loaders/ContentPlaceholdersText.vue'
import helpscout from './plugins/helpscout-beacon'
import Interval from './plugins/interval'
import { options as FloatingVueOptions, VTooltip } from 'floating-vue'
import { formatPhoneNumber, phoneDirective } from './plugins/phone'

import { RecycleScroller } from 'vue-virtual-scroller'

// import ScrollSnap from './plugins/scroll-snap'

// require('vue-tour/dist/vue-tour.css')
const isProduction = process.env.NODE_ENV === 'production'
const isDevelopment = process.env.NODE_ENV === 'development'
if (process.env.VUE_APP_DISABLE_SENTRY != 'true') {
  SentryInit({
    Vue,
    dsn: 'https://a24f79b71ab240c3ae0e8833121228ae@sentry.io/1472604',
    integrations: [
      new browserTracingIntegration({
        router,
        tracingOrigins: [/api([^.]+)?.refera.com/],
      }),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category == 'xhr') {
        if (
          [
            'track.refera.com',
            't.refera.com',
            'pndsn.com',
            'doubleclick.net',
            'stripe.com',
            'google-analytics.com',
          ].some((s) => breadcrumb?.data?.url?.indexOf(s))
        )
          return null
      }
      return breadcrumb
    },
    beforeSend(event, hint) {
      const ignoreErrors = [
        'Avoided redundant navigation',
        'No token supplied to _authorizedJsonRequest',
        'Navigation cancelled from',
        'Redirected when going from',
      ]

      const isErrorContains = (msg) =>
        event?.exception?.values?.[0]?.value?.includes(msg) || hint?.originalException?.message?.includes(msg)

      const isIgnoredError = () => ignoreErrors.some(isErrorContains)

      if (isIgnoredError()) return null

      const isNonErrorException =
        isErrorContains('Non-Error exception captured') || isErrorContains('with keys: errors')

      if (isNonErrorException) {
        if (!event.extra.__serialized__) {
          return null
        }
        let rMsg = event.extra.__serialized__?.errors?.detail || null
        if (rMsg) {
          event.exception.values[0].value = rMsg
          event.message = rMsg
        }
      }

      if (
        typeof hint.originalException === 'object' &&
        hint.originalException != null &&
        'key' in hint.originalException
      ) {
        event.extra = {
          ...event.extra,
          ...hint.originalException,
        }
      }
      return event
    },
    tracesSampleRate: isProduction ? 0.2 : 1,
    profilesSampleRate: isProduction ? 0.2 : 1,
    debug: false,
    trackComponents: true,
    hooks: ['mount', 'update', 'unmount'],
    tracingOptions: {
      trackComponents: true,
      timeout: 500,
      hooks: ['mount', 'update'],
    },
    environment: process.env.VUE_APP_ENV_NAME,
    release: 'refera-web@' + process.env.VUE_APP_VERSION + '-' + process.env.VUE_APP_BUILD_VERSION,
  })
  setTag('version', process.env.VUE_APP_VERSION)
} else console.info('Sentry is disabled')

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: (input) => (input === 'just now' ? input : input + ' ago'),
    s: 'just now',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})
Vue.prototype.moment = moment
Vue.prototype.confirm = (...args) => window.confirm.apply(window, args) //todo: this is for X'ing the referral - could use VueRouter navigation guards and imlement a prettier custom dialog w/ isDirty flags;

Vue.config.productionTip = false
Vue.prototype.$exception = isDevelopment ? console.error : captureException

Vue.component('base-layout', () => import('./components/_layout/Base.vue'))
Vue.component('baseanonymous-layout', () => import('./components/_layout/BaseAnonymous.vue'))
Vue.component('baseprint-layout', () => import('./components/_layout/BasePrint.vue'))
Vue.component('baseinbox-layout', () => import('./components/_layout/BaseInbox.vue'))

Validator.extend('required', required)
Validator.extend('regex', regex)
Validator.extend('email', email)
Validator.localize('en', veeEn)
Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    invalid: 'is-invalid',
  },
})
Vue.use(VuejsDialog, { html: true })
// Vue.use(VueTour)
Vue.use(Toasted, {
  keepOnHover: true,
  duration: 15e3,
  theme: 'toasted-primary',
  className: 'refera-toast',
  iconPack: 'callback', //'fontawesome',
  position: 'top-right',
})
Vue.component('RecycleScroller', RecycleScroller)
// Vue.use(
//   VueGtag,
//   {
//     config: {
//       id: 'UA-156430742-1',
//       linker: {
//         accept_incoming: true
//       }
//     }
//     // config: {
//     //   id: 'AW-607347500',
//     //   linker: {
//     //     accept_incoming: true
//     //   }
//     // }
//   },
//   router
// )
Vue.use(helpers, router, store)

Vue.component('Placeholders', ContentPlaceholders)
Vue.component('PlaceholdersHeading', ContentPlaceholdersHeading)
Vue.component('PlaceholdersImg', ContentPlaceholdersImg)
Vue.component('PlaceholdersText', ContentPlaceholdersText)

Vue.component('paginate', Paginate)
Vue.component('card-header', CardHeader)

Vue.component('from-now', {
  name: 'FromNow',
  props: {
    tag: { type: String, default: 'span' },
    time: { default: () => moment().toISOString() },
    dropFixes: { default: false, type: Boolean },
  },
  data() {
    return { fromNow: moment(this.time).fromNow(this.dropFixes) }
  },
  mounted() {
    Interval.register(this.updateFromNow)
    this.$watch('time', this.updateFromNow)
  },
  beforeDestroy() {
    Interval.unregister(this.intervalId)
  },
  methods: {
    updateFromNow() {
      var newFromNow = moment(this.time).fromNow(this.dropFixes)
      if (newFromNow !== this.fromNow) {
        this.fromNow = newFromNow
      }
    },
  },

  render(h) {
    return h(this.tag, { key: this.fromNow }, this.fromNow)
  },
})

Vue.filter('formatSize', function (size) {
  size = parseInt(size || 0)
  var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
})

Vue.prototype.$Notification = window.Notification
Vue.prototype.$isMobile = Device.mobile()
Vue.prototype.$track = function () {}
Vue.use(WithPremium, router, store)
// Vue.use(ScrollSnap)
// Vue.use({
//   install(Vue, options) {
//     Vue.prototype.globalFunc = () => {}
//   }
// })

// Vue.use(PostHog, router, store)
// Vue.use(ybug, router, store)
Vue.use(helpscout, router, store)

// Vue.filter('phone', (p) => {
//   if (/\(\d{3}\)\s\d{3}-\d{4}$/.test(p || '')) return p
//   p = p?.replace(/\D/g, '')
//   return (p?.length >= 10 && p.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})(.+)?$/, '($1) $2-$3')) || p
// })
Vue.filter('phone', formatPhoneNumber)
Vue.directive('phone-input', phoneDirective)

//this doesnt treeshake, need to custom build floating-vue
FloatingVueOptions.distance = 5
FloatingVueOptions.themes.tooltip.delay.show = 100
FloatingVueOptions.themes.tooltip.delay.hide = 300
FloatingVueOptions.themes['light-tooltip'] = {
  $extend: 'tooltip',
}
FloatingVueOptions.themes['light-sm-tooltip'] = {
  $extend: 'tooltip',
}
FloatingVueOptions.themes['light-lg-tooltip'] = {
  $extend: 'light-tooltip',
}
Vue.directive('tooltip', VTooltip)

if (process.env.NODE_ENV === 'development') {
  Vue.config.errorHandler = function (err, vm, info) {
    console.warn(info)
    console.error(err)
    throw err
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
  }
  window.onerror = function (msg, url, line, col, error) {
    console.warn(msg, url, line, col)
    console.error(error)
    //code to handle or report error goes here
  }
}

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
