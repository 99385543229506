import Vue from 'vue'
import { faInfoCircle, faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { icon } from '@fortawesome/fontawesome-svg-core'

const infoIcon = icon(faInfoCircle, {
  transform: {
    size: 24,
  },
})?.html
const checkIcon = icon(faCheckCircle, {
  transform: {
    size: 24,
  },
})?.html
const errorIcon = icon(faTimesCircle, {
  transform: {
    size: 24,
  },
})?.html

const state = {
  notifications: [],
}

const mutations = {
  addNotification(state, notification) {
    state.notifications = [...state.notifications, notification]
  },
  removeNotification(state, id) {
    state.notifications = state.notifications.filter((n) => n.id !== id)
  },
}

const actions = {
  remove({ commit, state }, id) {
    const current = state.notifications.find((s) => s.id == id)
    if (!current) {
      console.warn(
        `Notification with id ${id} was not found.`,
        state.notifications.map((s) => ({ id: s.id, notification: s.notification }))
      )
      return
    }
    commit('removeNotification', id)
    current.toast?.goAway(0)
  },
  add({ commit, state, dispatch }, notification) {
    const current = state.notifications.find((s) => s.notification == notification?.notification)
    if (current) {
      dispatch('remove', current.id)
    }

    const opts = { ...Vue.toasted.options, ...notification?.opts }

    if (opts?.duration) {
      if (opts.duration != 15000) {
        console.warn('.durational css animation hardcoded to 15s. Add a new animation or set via JS.')
      }
      opts.className = ((opts.className && opts.className + ' ') || '') + 'durational'
    }
    const toast = Vue.toasted[notification.type](notification.notification, opts)
    const id = Date.now()
    const n = { ...notification, toast, id }
    commit('addNotification', n)
    setTimeout(() => {
      dispatch('remove', id)
    }, 15000) // * (state.notifications.length + 1))
  },
  success({ dispatch }, payload) {
    const notification = payload?.text || payload
    const opts = {
      icon:
        payload?.icon ||
        ((el) => {
          el.innerHTML = checkIcon
          return el
        }),
      ...payload?.opts,
    }
    dispatch('add', {
      notification,
      type: 'success',
      opts,
    })
  },
  info({ dispatch }, payload) {
    const notification = payload?.text || payload
    const opts = {
      icon: (el) => {
        el.innerHTML = infoIcon
        return el
      },
      ...payload?.opts,
    }
    dispatch('add', {
      notification,
      type: 'info',
      opts,
    })
  },
  error({ dispatch }, payload) {
    const notification = payload?.text || payload
    const opts = {
      icon: (el) => {
        el.innerHTML = errorIcon
        return el
      },
      ...payload?.opts,
    }
    dispatch('add', {
      notification,
      type: 'error',
      opts,
    })
  },
}

const getters = {
  errors: (state) => state.notifications.filter((n) => n.type === 'error'),
  success: (state) => state.notifications.filter((n) => n.type === 'success'),
  info: (state) => state.notifications.filter((n) => n.type === 'info'),
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
