import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { captureException } from '@sentry/vue'

/* Auth */
const UserAuth = () => import(/* webpackMode: "lazy-once" */ '@/views/auth/UserAuth')

Vue.use(VueRouter)

const importVuexModule = async (module) => {
  if (store.hasModule(module)) return
  const m = await import(`@/store/modules/${module}`)
  if (!store.hasModule(module)) store.registerModule(module, m.default)
}

const routes = [
  {
    path: '/status',
    name: 'Status',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Status' },
    component: () => import(/* webpackMode: "lazy-once" */ '@/views/public/Status'),
  },
  {
    path: '/referral/print/:id',
    component: () =>
      import(
        /* webpackChunkName: "printReferral" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/referrals/PrintReferral'
      ),
    meta: {
      layout: 'baseprint', //isPremiumOnly: true
      title: 'Print Referral',
    },
  },
  {
    name: 'create-referral-public',
    path: '/send/:to?/:id?',
    component: () =>
      import(
        /* webpackChunkName: "publicReferral" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/referrals/PublicReferral'
      ),
    meta: {
      autoPrint: false,
      isPublic: true,
      isSidebarEnabled: false,
      containerClass: 'edgeless',
      layout: 'baseanonymous',
      bgClass: 'public-referral',
      hideMenu: true,
      isOneSlipBranding: true,
    },
  },
  // {
  //   path: '/referral/create',
  //   redirect: (to) => {
  //     console.log('REFERRAL REDIRECRT', { to })
  //     const user = store.state.auth?.user
  //     const p = user?.practice
  //     if (p?.is_setup_complete) {
  //       const refData = p?.doctors?.length === 1 ? { referring_doctor_id: p.doctors[0]?.id } : {}
  //       let newReferral = createReferral(refData)
  //       //store.dispatch('referrals/createNew', newReferral)
  //       return '/referral/new/' + newReferral.id
  //     }
  //     return '/send'
  //   },
  // },
  {
    name: 'referral-view',
    path: '/referral/:id?',
    //ReferralInbox,
    component: () =>
      import(
        /* webpackChunkName: "referralInbox" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */
        '@/views/referrals/ReferralInbox.vue'
      ),
    meta: {
      autoPrint: false,
      isSidebarEnabled: false,
      containerClass: 'edgeless',
      layout: 'base',
      isBaseInbox: true,
    },
  },
  {
    name: 'referral-inbox',
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "referralInbox" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */
        '@/views/referrals/ReferralInbox.vue'
      ),
    meta: {
      autoPrint: false,
      isSidebarEnabled: false,
      containerClass: 'edgeless',
      layout: 'base',
      title: 'Create Referral',
      isBaseInbox: true,
    },
    beforeEnter: (to, from, next) => {
      //check from send or have a param.force
      //todo: probably need to introduce a third state for those coming from onesli\, ofr now using phone
      const user = store.state.auth?.user
      if (!user || user.is_guest || !user.practice?.is_setup_complete) next('/send')
      else {
        // if (store.getters['referrals/all']?.[0]?.id) next(`/referral/${store.getters['referrals/all'][0].id}`)
        // else
        next()
      }
    },
  },
  {
    path: '/op-report/print/:id',
    component: () =>
      import(
        /* webpackChunkName: "opreports" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/op-reports/PrintOpReport'
      ),
    meta: {
      layout: 'baseprint',
      baseClass: 'print-height-auto',
      title: 'Print Op Report',
      // isPremiumOnly: true
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Privacy Policy' },
    component: () =>
      import(
        /* webpackChunkName: "legal" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/public/legal/PrivacyPolicy'
      ),
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Terms of Use' },
    component: () =>
      import(
        /* webpackChunkName: "legal" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/public/legal/TermsOfUse'
      ),
  },
  {
    path: '/business-agreement',
    name: 'BusinessAgreement',
    meta: {
      layout: 'baseanonymous',
      isPublic: true,
      title: 'Business Agreement',
    },
    component: () =>
      import(
        /* webpackChunkName: "legal" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/public/legal/BusinessAgreement'
      ),
  },
  {
    path: '/ota/:email/:token',
    name: 'AutoLoginWithOtaCode',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Login' },
    component: UserAuth,
  },
  {
    path: '/login/verify/:email/:token',
    name: 'AutoLoginWithOtaCode_old',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Login' },
    component: UserAuth,
  },
  {
    path: '/login/verify/:email',
    name: 'LoginVerifyEmailToken',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Login' },
    component: UserAuth,
  },
  {
    path: '/login/:token?',
    name: 'Login',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Login' },
    component: UserAuth,
  },
  // {
  //   path: '/demo',
  //   name: 'DemoLogin',
  //   meta: { layout: 'baseanonymous', isPublic: true, title: 'Login Demo' },
  //   component: DemoLogin
  // },
  {
    path: '/create-account/:uid/:token',
    name: 'ProvisionUser',
    meta: {
      layout: 'baseanonymous',
      isPublic: true,
      bgClass: 'register-bg',
      title: 'New User',
    },
    component: () =>
      import(
        /* webpackChunkName: "authpublic" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/public/ProvisionUser'
      ),
  },
  {
    path: '/r/:inviteKey/:referral?/:opreport?',
    name: 'RegisterInviteShorthand',
    meta: {
      layout: 'baseanonymous',
      isPublic: true,
      bgClass: 'register-bg',
      title: 'Welcome to paperless referrals',
    },
    component: () =>
      import(
        /* webpackChunkName: "authpublic" */
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/public/AcceptInvite'
      ),
  },
  {
    path: '/register/:inviteKey/:referral?/:opreport?',
    name: 'RegisterInvite',
    meta: {
      layout: 'baseanonymous',
      isPublic: true,
      bgClass: 'register-bg',
      title: 'Welcome to paperless referrals',
    },
    component: () =>
      import(
        /* webpackChunkName: "authpublic" */
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/public/AcceptInvite'
      ),
  },
  {
    path: '/register',
    name: 'RegisterBase',
    meta: {
      layout: 'baseanonymous',
      isPublic: true,
      bgClass: 'register-bg',
      title: 'Get Started',
    },
    component: () =>
      import(
        /* webpackChunkName: "authpublic" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/public/RegisterLayout'
      ),
    children: [
      {
        path: '',
        name: 'Register',
        meta: {
          layout: 'baseanonymous',
          isPublic: true,
          bgClass: 'register-bg',
          title: 'Get Started',
        },
        component: () =>
          import(
            /* webpackChunkName: "authpublic" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/public/SignUp'
          ),
      },
    ],
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ConfirmPassword',
    meta: {
      layout: 'baseanonymous',
      isPublic: true,
      isTopLogoHidden: true,
      title: 'Forgot Password',
    },
    component: () =>
      import(
        /* webpackChunkName: "authpublic" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/auth/ConfirmPassword'
      ),
  },
  {
    path: '/insights',
    name: 'Insights',
    meta: { layout: 'base', isOrganizationAllowed: true, title: 'Insights' },
    beforeEnter: async (to, from, next) => {
      let d = await importVuexModule('report')
      if (store.hasModule('report')) next()
      else next('/error/report-failed')
    },
    component: () =>
      import(
        /* webpackChunkName: "insights" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/insights/Report'
      ),
  },
  {
    path: '/admin',
    meta: {
      layout: 'base',
      isOrganizationAllowed: true,
      title: 'Admin Report',
    },
    beforeEnter: async (to, from, next) => {
      let d = await importVuexModule('adminReport')
      if (store.hasModule('adminReport')) next()
      else next('/error/admin-report-failed')
    },
    component: () =>
      import(
        /* webpackChunkName: "admin" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */ '@/views/insights/AdminReport'
      ),
  },
  {
    path: '/',
    meta: {
      layout: 'base',
      isSidebarEnabled: false,
      isOrganizationAllowed: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "mainapp" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: false */
        /* webpackPreload: false */

        '@/components/_layout/Content'
      ),
    children: [
      {
        path: '/op-report',
        meta: {
          layout: 'base',
          isSidebarEnabled: false,
          isOrganizationAllowed: true,
          title: 'Op Reports',
        },
        component: () =>
          import(
            /* webpackChunkName: "mainapp" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */

            '@/components/_layout/Content'
          ),
        children: [
          {
            path: 'send/:id',
            component: () =>
              import(
                /* webpackChunkName: "opreports" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/op-reports/OpReportForm'
              ),
            props: { showSendModal: true },
            meta: {
              title: 'Send Op Report',
            },
          },
          {
            path: 'create',
            component: () =>
              import(
                /* webpackChunkName: "opreports" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/referrals/PickReferringOffice'
              ),
            props: {
              redirect: 'op-report',
              title: 'Send Op Report',
              subtitle: 'Who are you sending an op report to?',
              default: null,
            },
            meta: {
              title: 'Send Op Report',
            },
          },
          {
            path: ':id?/:referral?',
            component: () =>
              import(
                /* webpackChunkName: "opreports" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/op-reports/OpReportForm'
              ),
            meta: {
              title: 'Send Op Report',
            },
          },
        ],
      },
      {
        path: '/op-reports',
        name: 'opReportsList',
        meta: {
          layout: 'base',
          isOrganizationAllowed: true,
          title: 'Op Reports',
        },
        component: () =>
          import(
            /* webpackChunkName: "opreports" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/op-reports/OpReportList'
          ),
      },

      {
        path: 'practice/:id?',
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/settings/practice/network/ViewPractice'
          ),
        meta: { isOrganizationAllowed: true, title: 'View Practice' },
      },
      {
        path: 'manage',
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/settings/Settings'
          ),
        meta: { title: 'Settings' },
        children: [
          {
            path: 'users',
            component: () =>
              import(
                /* webpackChunkName: "settings" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/settings/users/ManageUsers'
              ),
            meta: { title: 'Users | Settings' },
          },
          {
            path: 'users/user/:id?',
            component: () =>
              import(
                /* webpackChunkName: "settings" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/settings/users/EditUser'
              ),
            meta: { title: 'User | Settings' },
          },
          {
            name: 'ManageDoctors',
            path: 'doctors',
            component: () =>
              import(
                /* webpackChunkName: "settings" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/settings/users/AddDoctors'
              ),
            meta: { title: 'Doctors | Settings' },
          },
          {
            path: 'network/add-practice',
            component: () =>
              import(
                /* webpackChunkName: "settings" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/settings/practice/network/AddPractice'
              ),
            meta: { title: 'Add Practice' },
          },
          {
            path: 'practice',
            component: () =>
              import(
                /* webpackChunkName: "settings" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/settings/practice/ManagePractice'
              ),
            meta: { isOrganizationAllowed: true, title: 'Practice' },
          },
          {
            path: 'billing',
            component: () =>
              import(
                /* webpackChunkName: "settings" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/settings/billing/Payment'
              ),
            meta: { isOrganizationAllowed: true, title: 'Billing' },
          },
          {
            path: 'settings',
            component: () =>
              import(
                /* webpackChunkName: "settings" */
                /* webpackMode: "lazy" */
                /* webpackPrefetch: false */
                /* webpackPreload: false */ '@/views/settings/practice/Settings'
              ),
            meta: { isOrganizationAllowed: true, title: 'Settings' },
          },
        ],
      },
      {
        path: 'manage/help',
        component: () =>
          import(
            /* webpackChunkName: "about" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/settings/Help'
          ),
        meta: { title: 'Help' },
      },
      {
        path: 'pms-integration/sikka-complete',
        component: () =>
          import(
            /* webpackChunkName: "sikkaintegration" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/settings/pms/Sikka'
          ),
        props: { isComplete: true },
      },
      {
        path: 'pms-integration',
        component: () =>
          import(
            /* webpackChunkName: "sikkaintegration" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/settings/pms/Sikka'
          ),
      },
      {
        name: 'view-op-report',
        path: '/view/op-report/:id',
        component: () =>
          import(
            /* webpackChunkName: "opreports" */
            /* webpackMode: "lazy" */
            /* webpackPrefetch: false */
            /* webpackPreload: false */ '@/views/op-reports/ViewOpReport'
          ),
        meta: {
          autoPrint: false,
          isSidebarEnabled: false,
          title: 'View Op Report',
        },
      },
      {
        path: 'activity',
        component: () => import('@/components/activity/ActivityList'),
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    meta: { layout: 'baseanonymous', isPublic: true, title: 'Error' },
    component: () => import(/* webpackMode: "lazy-once" */ '@/views/500'),
  },
  ,
  {
    path: '*',
    name: '404',
    meta: { layout: 'baseanonymous', isPublic: true, title: '404' },
    component: () => import(/* webpackMode: "lazy-once" */ '@/views/404'),
  },
]
// [
//   {
//     path: "/",
//     name: "Home",
//     component: Home
//   },
//   {
//     path: "/about",
//     name: "About",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ "../views/About.vue")
//   }
// ];

const router = new VueRouter({
  routes,
})

let isTokenVerified = false

async function validateToken(token, logoutIfInvalid = false) {
  //todo: we ought to iterate through all logins and valid them all
  if (!store.getters['auth/isTokenAvailable'] && !token) {
    if (logoutIfInvalid) store.dispatch('auth/clientLogout', { preventRedirect: true })
    return false
  }
  if (isTokenVerified && !token) return true
  try {
    await store.dispatch('auth/me', { token })
    isTokenVerified = true
    return true
  } catch (e) {
    return false
  }
}

const prefetchStateByName = (name) => {
  if (!store.state[name].isLoaded) {
    return store.dispatch(name + '/load')
  }

  return Promise.resolve()
}

const prefetchData = () => {
  return Promise.all(['users', 'practices'].map(prefetchStateByName))
}

let isConfigLoaded = false

router.beforeEach(async (to, from, next) => {
  if (!isConfigLoaded)
    prefetchStateByName('settings').catch((err) => {
      store.dispatch('notifications/error', {
        text: "Failed to contact refera's server.",
        opts: {
          duration: null,
          action: {
            text: 'Reload',
            href: '/',
            onClick: (e, obj) => self.location.reload(),
            dontClose: true,
          },
        },
      })
      captureException(err)
    })
  // console.log('router:beforeEach', { to, from })
  document.title = (to.meta?.title || 'Referrals') + ' | Refera'
  // we add the query here so auth/me doesn't default to any existing token
  var isTokenValid = validateToken(to?.query?.t, !to?.query?.t)
  const isPublicUser = store.getters['auth/isPublicLoggedIn']
  // console.log({ to, from, isTokenValid, isPublicUser })

  if (to.meta.isPublic) next()
  else if (!isPublicUser && (await isTokenValid)) {
    prefetchData()

    if (to.query.p) {
      await store.dispatch('auth/switchPractice', to.query.p)
    }
    // if (to.meta?.isPublicUserAllowed && isPublicUser) {
    //   next(from.path)
    //   return
    // }
    store.dispatch('settings/setIsBannerVisible', to.meta?.isBannerVisible)
    if (to.meta?.isPremiumOnly && !store.getters['billing/isPremium']) {
      store.dispatch('settings/setIsPaymentModalVisible', true)
      next(from.path) //todo: this doesnt redirect back to from, might need to use named routes throughout - nor does false when opened in new window
      return
    }
    next()
  } else {
    next({
      name: 'Login',
      query: {
        from: from.meta?.isPublic ? from.query.from : btoa(window.location.hash.substr(1)),
      },
    })
  }
})

router.afterEach((to, from) => {
  if (to.query.tour) {
    store.dispatch('tour/setActiveTour', to.query.tour)
    // setTimeout(() => store.dispatch('tour/setActiveTour', to.query.tour), 1500)
  }
})

export default router
