export default [
  {
    name: 'Send Unlimited Referrals',
    info: 'Create and send referrals without limits.',
    icon: require('@/assets/svg/paper-plane.svg')
  },
  // {
  //   name: 'Unlimited SMS',
  //   info: 'Convenient chat for efficient scheduling.',
  //   icon: require('@/assets/svg/sms-dualtone.svg'),
  //   isSpecialistOnly: true
  // },
  {
    name: 'Patient Referral via Text',
    info: 'Send a copy of the referral to the patient.',
    icon: require('@/assets/svg/sms-unanswered.svg')
  },
  // {
  //   name: 'Print & Download',
  //   info: 'Save, print and download all documents.',
  //   icon: require('@/assets/svg/download-dualtone.svg')
  // },
  {
    name: 'Print, Download, Copy',
    info: 'Save, print and download all documents.',
    icon: require('@/assets/svg/download-dualtone.svg')
  },
  // {
  //   name: 'Print & Download',
  //   info: 'Optimized print view and one-click PDF export.',
  //   icon: require('@/assets/svg/integration-dualtone.svg')
  // },
  // {
  //   name: 'Copy',
  //   info: 'Copy all referral data, including attachments.',
  //   icon: require('@/assets/svg/integration-dualtone.svg')
  // },
  {
    name: 'Insights',
    info: 'Friendly reports with actionable data.',
    icon: require('@/assets/svg/insights-dualtone.svg')
  },
  // {
  //   name: 'Enhanced Reach', //Promoted office
  //   info: 'Be promoted to all offices within 30 miles.',
  //   icon: require('@/assets/svg/featured-dualtone.svg'),
  //   isSpecialistOnly: true
  // },
  {
    name: 'Custom Branding',
    info: 'Put your logo on your referrals.',
    icon: require('@/assets/svg/insurance-dualtone.svg')
  },
  {
    name: 'Integration',
    info: 'Auto populate referrals and more.',
    icon: require('@/assets/svg/integration-dualtone.svg'),
    planMeta: ['integration']
  }
]
