<template>
  <div :class="className">
    <div
      v-for="n in lines"
      :key="n"
      :class="`${className}__line ${lineClass}`"
      :style="{ height: height }"
    />
  </div>
</template>

<script>
export default {
  name: 'content-placeholders-text',
  props: {
    lineClass: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '1rem',
    },
    lines: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      className: 'vue-content-placeholders-text',
    }
  },
}
</script>
