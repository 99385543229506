// import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
// export const generateClientId = uuidv4
// this requires adding uuid 8+ dependency to package.json
// export const isClientId = uuidValidate

//this is much faster than the above
//export const generateClientId = () => `r-${Date.now().toString(36)}-${Math.random().toString(36).slice(2, 11)}`
//export const isClientId = (id) => /^r-[a-z0-9]+-[a-z0-9]{9}$/i.test(id)

export const generateClientId = () => `ref-${Math.random().toString(36).slice(2, 7)}`
export const isClientId = (id) => id?.startsWith?.('ref-')
export const isServerId = (id) => /^[0-9]+$/.test(id)

export function ensureCid(obj, stateReferrals, _id) {
  if (!stateReferrals && !_id) console.warn('ensureCid called without state')

  if (Array.isArray(obj)) {
    if (_id) throw new Error('Cannot pass _id to ensureCid for arrays')
    return obj.map((s) => ensureCid(s, stateReferrals, _id))
  }

  // We have a server object without a client id, and we are to replace it with our client _id
  if (_id && !obj._id) {
    return { ...obj, id: _id, _id: obj.id }
  }

  _id =
    obj._id || _id || stateReferrals?.find((r) => r.id == obj.id || r.id == _id)?._id || obj.id || generateClientId()

  return { ...obj, id: obj.id || obj._id, _id }
}

// Merge function to handle local and server referrals
export function mergeReferrals(serverReferrals, localReferrals) {
  const merged = [...localReferrals]

  serverReferrals.forEach((s) => {
    const localIndex = localReferrals.findIndex((l) => l._id === (s._id || s.id) || l.id === s.id)
    if (localIndex > -1) {
      const local = localReferrals[localIndex]
      // Update the existing local referral
      merged[localIndex] = ensureCid({ ...s, attachments: local.attachments }, localReferrals, local.id) // isClientId(s._id || s.id) ? s._id || s.id : null)
    } else {
      // Add new server referral
      merged.push(ensureCid(s, localReferrals))
    }
  })

  return merged
}

export const debounceWithArgs = (fn, delay = 1000) => {
  let timerId = {}
  return function (...args) {
    const context = this
    const key = JSON.stringify(args)

    clearTimeout(timerId[key])

    timerId[key] = setTimeout(() => {
      delete timerId[key]
      fn.apply(context, args)
    }, delay)
  }
}

export function debounce(func, wait = 100) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}

export const compareVersions = (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
