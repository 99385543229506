import { captureException } from '@sentry/vue'
import { SettingsApi } from '../../services'
import { WITH_TEXT, LOGO } from './LOGOS'
import { compareVersions } from './utils'

const DEFAULT_CONFIG = {
  v: process.env.VUE_APP_VERSION,
  anon: parseInt(process.env.VUE_APP_ANON_PID || '1'), //preload before server provides latest
  rl: parseInt(process.env.VUE_APP_REFERRAL_LIMIT || '20'),
  pt: {},
}
const state = {
  isSidebarVisible: false,
  isSidebarEnabled: false,
  isGettingStartedVideoVisible: false,
  isPaymentModalVisible: false,
  isBannerVisible: true,
  config: DEFAULT_CONFIG,
  isLoading: false,
  logo: 1,
  error: null,
  clientVersion: process.env.VUE_APP_VERSION,
  env: process.env.VUE_APP_ENV_NAME,
  toothNumberSystem: 'uns',
  loadIntervalId: null,
}

/*
export interface RemoteConfig {
  v: string
  dn: string
  api: string
  pub: string
  app: string
  dm: number
  anon: number
  pt: Pt
  ts: string,
  rl: number // referral limit
  mscv: string
}
*/

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setError(state, error) {
    state.error = error
  },
  setConfig(state, config) {
    state.config = config
  },
  setSidebar(state, isVisible) {
    state.isSidebarVisible = isVisible
  },
  setSidebarEnabled(state, isEnabled) {
    state.isSidebarEnabled = isEnabled
  },
  setIsGettingStartedVideoVisible(state, isVisible) {
    state.isGettingStartedVideoVisible = isVisible
  },
  setIsPaymentModalVisible(state, isVisible) {
    state.isPaymentModalVisible = isVisible ?? !state.isPaymentModalVisible
  },
  setIsBannerVisible(state, isVisible) {
    state.isBannerVisible = isVisible
  },
  setLogo(state, logo) {
    state.logo = logo
  },
  setToothNumberSystem(state, toothNumberSystem) {
    state.toothNumberSystem = toothNumberSystem
  },
  setVersionCheckIntervalId(state, intervalId) {
    state.versionCheckIntervalId = intervalId
  },
}

const actions = {
  async load({ dispatch, commit }) {
    const intervalId = setInterval(() => {
      dispatch('_load')
    }, 5 * 60 * 1000)
    commit('setVersionCheckIntervalId', intervalId)
    return dispatch('_load')
  },
  async _load({ commit, state, dispatch }) {
    commit('setError', null)
    if (state.config?.ts) return state.config

    commit('setLoading', true)
    let config = null
    try {
      config = await SettingsApi.all({}, { anonymous: true })
      if (config?.anon !== state.config?.anon) {
        console.warn('Config mismatch on server', { l: config?.anon, s: state.config?.anon })
      }
      commit('setConfig', config)

      //check if the client version is the same as the server version
      if (compareVersions(state.clientVersion, config.mscv) < 0) {
        const currentUrl = new URL(window.location.href)
        const requestedVersion = currentUrl.searchParams.get('cv')
        if (requestedVersion) {
          captureException(
            new Error(`Client version updated failed. Request ${requestedVersion} and got ${state.clientVersion}`)
          )
          if (state.env !== 'production') {
            dispatch('notifications/error', 'Update failed. Clear cache and force refresh page.', {
              root: true,
            })
          }
        } else {
          dispatch(
            'notifications/info',
            {
              text: 'Update required. Please reload the page.',
              opts: {
                duration: null,
                action: {
                  text: 'Reload',
                  href: '/',
                  onClick: (e, obj) => {
                    currentUrl.searchParams.set('cv', config.mscv)
                    window.location.href = currentUrl.toString()
                  },
                  dontClose: true,
                },
              },
            },
            { root: true }
          )
        }
      }
    } catch (err) {
      console.error(err)
      commit('setConfig', DEFAULT_CONFIG)
      commit('setError', err)
      commit('setLoading', false)
      throw err
    }
    commit('setLoading', false)
    return config
  },
  setSidebar({ commit, state }, isVisible) {
    commit('setSidebar', isVisible)
  },
  setSidebarEnabled({ commit, state }, isEnabled) {
    commit('setSidebarEnabled', isEnabled)
  },
  toggleSidebar({ commit, state }) {
    commit('setSidebar', !state.isSidebarVisible)
  },
  setIsGettingStartedVideoVisible({ commit, state }, isVisible) {
    commit('setIsGettingStartedVideoVisible', isVisible)
  },
  setIsPaymentModalVisible({ commit, state }, isVisible) {
    commit('setIsPaymentModalVisible', isVisible ?? !state.isPaymentModalVisible)
  },
  setIsBannerVisible({ commit, state }, isVisible) {
    commit('setIsBannerVisible', isVisible)
  },
  setToothNumberSystem({ commit }, toothNumberSystem) {
    const tns = {
      0: 'uns',
      1: 'fdi',
      uns: 'uns',
      fdi: 'fdi',
    }

    if (toothNumberSystem in tns) {
      commit('setToothNumberSystem', tns[toothNumberSystem] + '')
    } else {
      console.error('Invalid tooth number system', toothNumberSystem)
      return
    }
  },
  //TODO: Not used but should have a global pubnub channel, anon users too
  stateGlobalUpdate: async ({ commit, state, dispatch }, { type, data }) => {
    if (!type) return
    if (type == 'message') {
      // {text, opts}
      dispatch('notifications/' + (data.type || 'info'), { ...data.message }, { root: true })
    }
    if (type == 'version') {
      if (compareVersions(state.clientVersion, data.version) < 0) {
        dispatch('load')
      }
    }
  },
}

const getters = {
  PracticeTypes(state) {
    return Object.keys(state.config.pt)
  },
  WithReasons(state) {
    const withReasons = {}
    for (const practiceType in state.config.pt) {
      withReasons[practiceType] = state.config.pt[practiceType].reasons
    }
    return withReasons
  },
  FlatReasons: (state, getters) =>
    Object.keys(getters.WithReasons).reduce((a, c) => ({ ...a, ...getters.WithReasons[c] }), {}),

  PracticeDataArray: (state) =>
    Object.entries(state.config.pt).map(([name, { plural, abbr, reasons }]) => ({
      name: name,
      plural,
      abbr,
      reasons,
    })),

  PracticeTypesPlural(state, getters) {
    return getters.PracticeDataArray.map(({ plural }) => plural)
  },
  PracticeTypesAbbr(state) {
    return Object.values(state.config.pt).map(({ abbr }) => abbr)
  },
  DemoId: (state) => state.config?.dm,
  AnonId: (state) => state.config?.anon,
  Logo: (state) => LOGO[state.logo % LOGO.length],
  LogoText: (state) => WITH_TEXT[state.logo % WITH_TEXT.length],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
