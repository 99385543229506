import Vue from 'vue'
var Interval = new Vue({
  created() {
    this.listeners = 0 // set non-reactive counter
  },
  methods: {
    start() {
      this.interval = setInterval(this.emit, 10000)
    },
    emit() {
      this.$emit('tick')
    },
    register(cb) {
      if (!cb) return
      if (this.listeners === 0) {
        this.start()
      }
      this.listeners++
      this.$on('tick', cb)
    },
    unregister(cb) {
      if (!cb) return
      this.listeners--
      this.$off('tick', cb)
      if (this.listeners === 0) {
        clearInterval(this.interval)
      }
    }
  }
})
export default Interval
