import RevampRestClient from './RestService'
import ReferaDummyClient from './StorageService'

export const PatientApi = new RevampRestClient('patients')
export const ReferralApi = new RevampRestClient('referrals')
export const OneSlipApi = new RevampRestClient('oneslip')
export const ReferralDemoApi = new ReferaDummyClient()
export const PracticeApi = new RevampRestClient('practices')
export const AttachmentApi = new RevampRestClient('attachments')
export const HistoryApi = new RevampRestClient('history')
export const UserApi = new RevampRestClient('users')
export const AppointmentApi = new RevampRestClient('referrals/scheduled')
export const IncomingReferralsApi = new RevampRestClient('referrals/incoming')
export const OutgoingReferralsApi = new RevampRestClient('referrals/outgoing')
export const DoctorApi = new RevampRestClient('users/doctors')
export const NetworkApi = new RevampRestClient('network')
export const AdminApi = new RevampRestClient('admin')
export const IntegrationApi = new RevampRestClient('integrations')
export const OpReportApi = new RevampRestClient('op-reports')
export const SettingsApi = new RevampRestClient('settings')
export const ActivityApi = new RevampRestClient('activity')
export const LocationApi = new RevampRestClient('locations')
