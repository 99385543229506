const toothlist = {
  UR: { name: 'Upper Right', fdi: 1 },
  UL: { name: 'Upper Left', fdi: 2 },
  LL: { name: 'Lower Left', fdi: 3 },
  LR: { name: 'Lower Right', fdi: 4 },

  1: { name: 'Upper Right Third Molar', fdi: 18, x: 58, y: 286 },
  2: { name: 'Upper Right Second Molar', fdi: 17, x: 64, y: 243 },
  3: { name: 'Upper Right First Molar', fdi: 16, x: 73, y: 207 },
  4: { name: 'Upper Right Second Bicuspid', fdi: 15, x: 91, y: 174 },
  5: { name: 'Upper Right First Bicuspid', fdi: 14, x: 113, y: 142 },
  6: { name: 'Upper Right Cuspid', fdi: 13, x: 138, y: 112 },
  7: { name: 'Upper Right Lateral Incisor', fdi: 12, x: 175, y: 94 },
  8: { name: 'Upper Right Central Incisor', fdi: 11, x: 215, y: 81 },

  9: { name: 'Upper Left Central Incisor', fdi: 21, x: 246, y: 81 },
  10: { name: 'Upper Left Lateral Incisor', fdi: 22, x: 288, y: 94 },
  11: { name: 'Upper Left Cuspid', fdi: 23, x: 325, y: 112 },
  12: { name: 'Upper Left First Bicuspid', fdi: 24, x: 348, y: 142 },
  13: { name: 'Upper Left Second Bicuspid', fdi: 25, x: 370, y: 174 },
  14: { name: 'Upper Left First Molar', fdi: 26, x: 388, y: 207 },
  15: { name: 'Upper Left Second Molar', fdi: 27, x: 399, y: 243 },
  16: { name: 'Upper Left Third Molar', fdi: 28, x: 405, y: 286 },

  17: { name: 'Lower Left Third Molar', fdi: 38, x: 400, y: 378 },
  18: { name: 'Lower Left Second Molar', fdi: 37, x: 392, y: 417 },
  19: { name: 'Lower Left First Molar', fdi: 36, x: 375, y: 454 },
  20: { name: 'Lower Left Second Bicuspid', fdi: 35, x: 362, y: 483 },
  21: { name: 'Lower Left First Bicuspid', fdi: 34, x: 340, y: 509 },
  22: { name: 'Lower Left Cuspid', fdi: 33, x: 315, y: 532 },
  23: { name: 'Lower Left Lateral Incisor', fdi: 32, x: 284, y: 548 },
  24: { name: 'Lower Left Central Incisor', fdi: 31, x: 247, y: 554 },

  25: { name: 'Lower Right Central Incisor', fdi: 41, x: 215, y: 554 },
  26: { name: 'Lower Right Lateral Incisor', fdi: 42, x: 178, y: 548 },
  27: { name: 'Lower Right Cuspid', fdi: 43, x: 145, y: 532 },
  28: { name: 'Lower Right First Bicuspid', fdi: 44, x: 118, y: 509 },
  29: { name: 'Lower Right Second Bicuspid', fdi: 45, x: 98, y: 483 },
  30: { name: 'Lower Right First Molar', fdi: 46, x: 79, y: 454 },
  31: { name: 'Lower Right Second Molar', fdi: 47, x: 63, y: 417 },
  32: { name: 'Lower Right Third Molar', fdi: 48, x: 57, y: 378 },

  A: { name: 'Upper Right Second Molar', fdi: 55, x: 132, y: 284 },
  B: { name: 'Upper Right First Molar', fdi: 54, x: 141, y: 242 },
  C: { name: 'Upper Right Cuspid', fdi: 53, x: 158, y: 215 },
  D: { name: 'Upper Right Lateral Incisor', fdi: 52, x: 182, y: 194 },
  E: { name: 'Upper Right Central Incisor', fdi: 51, x: 218, y: 184 },

  F: { name: 'Upper Left Central Incisor', fdi: 61, x: 245, y: 184 },
  G: { name: 'Upper Left Lateral Incisor', fdi: 62, x: 285, y: 194 },
  H: { name: 'Upper Left Cuspid', fdi: 63, x: 307, y: 215 },
  I: { name: 'Upper Left First Molar', fdi: 64, x: 322, y: 242 },
  J: { name: 'Upper Left Second Molar', fdi: 65, x: 332, y: 284 },

  K: { name: 'Lower Left Second Molar', fdi: 75, x: 328, y: 351 },
  L: { name: 'Lower Left First Molar', fdi: 74, x: 319, y: 386 },
  M: { name: 'Lower Left Cuspid', fdi: 73, x: 304, y: 418 },
  N: { name: 'Lower Left Lateral Incisor', fdi: 72, x: 282, y: 439 },
  O: { name: 'Lower Left Central Incisor', fdi: 71, x: 249, y: 448 },

  P: { name: 'Lower Right Central Incisor', fdi: 81, x: 216, y: 448 },
  Q: { name: 'Lower Right Lateral Incisor', fdi: 82, x: 183, y: 439 },
  R: { name: 'Lower Right Cuspid', fdi: 83, x: 160, y: 418 },
  S: { name: 'Lower Right First Molar', fdi: 84, x: 144, y: 386 },
  T: { name: 'Lower Right Second Molar', fdi: 85, x: 136, y: 351 },
}

Object.keys(toothlist).forEach((key) => {
  toothlist[key].uns = key
  if (/^[A-T]$/.test(key)) {
    toothlist[key].deciduous = true
  }
})

export default toothlist
