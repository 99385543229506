<template>
  <div class="w-100">
    <small class="text-muted d-block mb-2" v-if="!hideBack">
      <a @click="$router.go(-1)" href="javascript:void(0);" class="mr-2 align-self-center text-muted">
        <font-awesome-icon :icon="['far', 'angle-left']" size="sm" class="mr-1" />
        Back
      </a></small>
    <div class="flex-row d-flex">
      <h1 class="mb-0 text-nowrap overflow-hidden flex-fill">{{ title }}</h1>
      <div class="text-right ml-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'hideBack'],
}
//ONLY USED BY OPREPORTFORM
</script>
