import { UserApi } from '@/services'
import AuthService from '@/services/AuthService'

const state = {
  users: [],
  isLoading: false,
  isLoaded: false
}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
  addUser(state, user) {
    state.users.push(user)
  },
  saveUser(state, user) {
    const index = state.users.findIndex(u => u.id === user.id)
    if (index !== -1) state.users.splice(index, 1, user)
    else state.users.push(user)
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  }
}

const actions = {
  async load({ commit, state, rootState }) {
    commit('setLoading', true)
    try {
      let users = await UserApi.all()
      commit('setUsers', users)
    } catch (err) {
      commit('setUsers', [])
    }
    commit('setLoading', false)
    commit('setLoaded', true)
  },
  reset({ commit }) {
    commit('setLoaded', false)
    commit('setUsers', [])
  },
  onAuthChange: (
    { dispatch },
    { user, oldUser, isPracticeChanged, type }
  ) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (type>1) dispatch('load')
    }
  },
  save: async ({ commit, dispatch, rootState }, user) => {
    let isCurrentUser = rootState.auth.user.id === user.id
    let data = await (isCurrentUser ? AuthService : UserApi).save(user)
    commit('saveUser', data)
    if (isCurrentUser)
      await dispatch('auth/updateCurrentUser', data, { root: true })
    if (!user.id && user.is_doctor)
      //   //todo: need a better way to update state, should notify all network
      dispatch('practices/refreshCurrentUserPractice', data, { root: true })
    return data
  },
  completeWalkthrough: async ({ commit, dispatch, rootState }) => {
    return await dispatch('save', {
      ...rootState.auth.user,
      is_walkthrough_complete: true
    })
  }
}

const getters = {
  byId: state => userId => state.users.find(p => p.id == userId),
  doctors: state => state.users.filter(s => s.is_doctor)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
