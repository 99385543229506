import { ReferralApi, ReferralDemoApi, AttachmentApi } from '@/services'
import Vue from 'vue'
import moment from 'moment'
import helpers from '@/helpers'
import { mergeReferrals, ensureCid, isClientId } from './utils'
import { getField, updateField } from 'vuex-map-fields'
import { captureException } from '@sentry/vue'
import { prepReferralForSave, compareReferral } from '../../models/referral'

const cmp = (a, b) => (a > b) - (a < b)
const sortFields = {
  date: (a, b) =>
    cmp(
      new Date(a.sent_time || a.updated_at || a.client_version),
      new Date(b.sent_time || b.updated_at || b.client_version)
    ), //todo: improve performance and map a date prop on the getter
  patient: (a, b) =>
    `${a.patient_firstname} ${a.patient_lastname}` > `${b.patient_firstname} ${b.patient_lastname}` ? 1 : -1, //todo: consider adding middle name
  practice: (a, b) => (`${a.practice.name}` > `${b.practice.name}` ? 1 : -1), //todo: consider adding once we determine the view format
  status: (a, b) => (helpers.getStatus(a) > helpers.getStatus(b) ? 1 : -1), //todo: this could be normalised to the model so it doesnt have to be computed so often
  in_out: (a, b, state) => (a.is_incoming > b.is_incoming ? 1 : -1),
}

const sorter = (state) => (a, b) =>
  state.referrals.sortAscending
    ? sortFields[state.referrals.sortField](a, b, state)
    : sortFields[state.referrals.sortField](b, a, state)

const searchFields = [
  'patient_firstname',
  'patient_middlename',
  'patient_lastname',
  'patient_email',
  'patient_phone',
  'doctor',
  'practice.name',
  'to_practice.name',
  'referring_doctor.email',
  'referring_doctor.first_name',
  'referring_doctor.last_name',
  'guardian_name',
]
const contextPracticeFilter = (state) => (r) =>
  state.contextPracticeid == null ||
  r.to_practice_id == state.contextPracticeId ||
  r.practice.id == state.contextPracticeId

export const is_needs_action = (r, now) => {
  now = now || moment()
  if (helpers.isArchived(r)) return false
  // if (r.is_incoming && !(r.is_important || true))
  //   return !r.receiver_viewed_at_time
  if (!r.sent_time) return !r.cancelled_time
  const isScheduled = r.scheduled_time || r.is_marked_scheduled
  const isCompleted = r.completed_time
  const isCancelled = r.cancelled_time
  const isIncoming = r.is_incoming
  if (isIncoming) {
    return (
      !(isCompleted || isCancelled) && (!isScheduled || !r.scheduled_time || now.diff(r.scheduled_time, 'minutes') > 10)
    )
  }
  //|| r.to_practice.status !== 1
  else
    return (
      // false &&
      !isCancelled &&
      (!r.sent_time || (r.requires_followup && !(now.diff(r.completed_time, 'days') > 3 || r.followup_completed_time)))
    ) // || (isCompleted && now.diff(r.completed_time, 'days') < 1) || (isCancelled && now.diff(r.cancelled_time, 'days') < 3);
}

export const filters = {
  needs_action: (payload) => (r) => is_needs_action(r, payload.now),
  // needs_action: store => r => !helpers.isArchived(r, store) && !r.scheduled_time && !(r.completed_time || r.cancelled_time),
  // needs_action_OUTGOING: store => r => !helpers.isArchived(r, store) && (r.completed_time || r.cancelled_time || !r.sent_time),
  archived: (store) => (r) => helpers.isArchived(r, store),
  all: (store) => (r) => r,
  unsent: (store) => (r) => !r.cancelled_time && !r.sent_time,
  sent: (store) => (r) => r.sent_time && !r.is_incoming,
  received: (store) => (r) =>
    r.is_incoming && (r.to_practice.specialty == 0 || !r.scheduled_time) && !(r.completed_time || r.cancelled_time),
  to_follow_up: (store) => (r) => !r.cancelled_time && r.requires_followup && !r.followup_completed_time,
  scheduled: (store) => (r) =>
    r.is_incoming && (r.scheduled_time || r.is_marked_scheduled) && !(r.completed_time || r.cancelled_time),
  completed: (store) => (r) => r.is_incoming && (r.completed_time || r.cancelled_time),
}

const state = {
  referrals: [],
  totalCount: 0,
  results: null,
  isLoading: false,
  isLoaded: false,
  searchTerm: null,
  activeFilter: null,
  contextPracticeId: null,
  error: null,
  sortField: 'date',
  sortAscending: false,
  isSuccessModalShown: false,
  isLoadingAll: false,
  isLoadedAll: false,
  now: Date.now(),
  currentReferralId: null,
  clientIdMap: {},
  isDeferredLoadingEnabled: false,
}

function _resolve(path, obj = self, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

const mutations = {
  addIdMapping(state, { clientId, serverId }) {
    state.clientIdMap[clientId] = serverId
  },
  updateReferralField(state, field) {
    if (!state.currentReferralId) return updateField({}, '')
    //TODO: Could set the client version here, can have a isFormDirty check with it
    const idx = state.referrals.findIndex((r) => r.id == state.currentReferralId || r._id == state.currentReferralId)

    if (idx < 0) return updateField({}, '')
    state.referrals[idx]['_new'] = false
    state.referrals[idx]['client_version'] = Date.now()
    updateField(state.referrals[idx], field)
  },
  setReferrals(state, referrals) {
    Vue.set(state, 'referrals', referrals)
  },
  setCurrentReferralId(state, id) {
    state.currentReferralId = id
  },
  setTotalCount(state, count) {
    state.totalCount = count
  },
  saveReferral(state, referral) {
    if (!referral) throw `Cannot update state with empty referral`
    if (!referral.id && !referral._id) throw `Cannot update state with invalid referral`
    if (!referral.id) console.warn('Referral has no id', referral)
    if (!referral._id) {
      console.warn('Referral has no _id or same', referral)
      referral = ensureCid(referral, state.referrals)
      console.warn(`Referral has been assigned _id ${referral._id}`)
    }

    const index = state.referrals.findIndex((r) => r._id == referral._id || r.id == referral.id)

    if (index < 0) {
      state.referrals.push(referral)
    } else {
      Vue.set(state.referrals, index, referral)
      // Vue.set(state.referrals[index], 'attachments', referral.attachments)
    }
  },
  changeReferralId(state, { oldId, newId }) {
    if (oldId == newId) return
    const index = state.referrals.findIndex((r) => r._id == oldId)
    Vue.set(state.referrals[index], '_id', newId)
    Vue.set(state.referrals[index], 'id', newId)
  },
  patchReferral(state, referral) {
    if (!referral || !referral.id) throw `Cannot patch state with invalid referral`

    const index = state.referrals.findIndex((r) => r._id == referral.id)

    if (index < 0) {
      throw `Cannot patch state with non-existing referral: ${referral.id}`
    } else {
      Object.keys(referral)
        .filter((k) => k != 'id')
        .forEach((k) => Vue.set(state.referrals[index], k, referral[k]))
    }
  },
  saveAttachment(state, { referralId, attachment }) {
    if (!referralId || !attachment?.id)
      throw `Cannot update state with invalid attachment (${attachment?.id})  for ${referralId}`

    const index = state.referrals.findIndex((r) => r._id == referralId || r.id == referralId)
    if (index < 0) {
      throw `Cannot save attachment: Referral ${referralId} for attachment not found in state`
    } else {
      const existing = state.referrals[index]
      if (!existing.attachments) existing.attachments = []
      const aIndex = existing.attachments.findIndex((a) => a.id == attachment.id || a?.response?.id == attachment.id)

      if (aIndex < 0) {
        state.referrals[index].attachments.unshift(attachment)
      } else {
        let _id = existing.attachments[aIndex]._id
        let blob = existing.attachments[aIndex].blob
        if (attachment.response?.updated_at) {
          _id = attachment.id
          blob = attachment.blob
          attachment = attachment.response //convert response to attachment
        }
        Vue.set(state.referrals[index].attachments, aIndex, {
          ...attachment,
          blob,
          _id,
        })
      }
    }
  },
  deleteAttachment(state, { referralId, attachmentId }) {
    if (!referralId || !attachmentId) throw `Cannot delete attachment with invalid payload`

    const index = state.referrals.findIndex((r) => r._id == referralId || r.id == referralId)
    if (index < 0) {
      throw `Referral for deleting attachment not found in state`
    } else {
      const existing = state.referrals[index]
      existing.attachments = existing.attachments?.filter((a) => a.id != attachmentId)
    }
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  },
  setLoadingAll(state, isLoading) {
    state.isLoadingAll = isLoading
  },
  setLoadedAll(state, isLoaded) {
    state.isLoadedAll = isLoaded
  },
  setError(state, error) {
    state.error = error
  },
  setSort(state, { field, isAscending }) {
    if (!sortFields[field]) throw `A sorter for field ${field} has not been implemented.`
    state.sortField = field
    state.sortAscending = isAscending
  },
  //todo: should be a getter
  filterReferrals(state, { filter, term }) {
    state.activeFilter = filter || state.activeFilter //todo: access rootState and update filter based on specialty

    if (!term) {
      state.searchTerm = null
      state.results = state.referrals
    } else {
      state.searchTerm = term
      term = term.trim().toLowerCase()
      state.results = state.referrals.filter((r) =>
        searchFields.some((field) => (_resolve(field, r) || '').toLowerCase().includes(term))
      )
    }
  },
  setPracticeContext(state, practiceId) {
    state.contextPracticeId = practiceId
  },
  deleteReferral(state, id) {
    const index = state.referrals.findIndex((r) => r._id == id)
    if (index === -1) return
    Vue.delete(state.referrals, index)
  },
  setIsSuccessModalShown(state, isVisible) {
    state.isSuccessModalShown = isVisible
  },
  setIsDeferredLoadingEnabled(state, isEnabled) {
    state.isDeferredLoadingEnabled = isEnabled
  },
}

let cache = {}
function memoize(method, key) {
  return function () {
    let args = key || JSON.stringify(arguments)
    cache[args] = cache[args] || method.apply(this, arguments)
    return cache[args]
  }
}

const fetchReferrals = (state, commit, dispatch, getters) => async () => {
  commit('setLoadedAll', false)
  commit('setLoading', true)
  commit('setError', null)
  try {
    let referrals = await getters.service.all()
    commit('setReferrals', mergeReferrals(referrals.results, state.referrals))
    commit('setTotalCount', referrals.count)
    commit('setLoaded', true)
    commit('filterReferrals', { filter: 'Needs Action' })
    //todo: should defer this and only load lazily when scrolled, or search, or accessing a referral by id
    if (referrals.results?.length === referrals.count) commit('setLoadedAll', true)

    if (referrals.count < 500) {
      if (!(state.isLoadedAll || state.isLoadingAll)) dispatch('loadAll')
    } else {
      commit('setIsDeferredLoadingEnabled', true)
      console.warn(`Defer loading all ${referrals.count} referrals`)
    }

    commit('setLoading', false)
    return referrals
  } catch (err) {
    commit('setError', err)
    //we dont want to clear referrals in case we have drafts, if this ever fails then it should be handled by caller
    // commit('setReferrals', [])
    // commit('setTotalCount', 0)
    console.error(err)
    captureException(err)
  }
  commit('setLoading', false)
}

const actions = {
  _saveReferral({ commit, state }, referral) {
    if (!referral) throw `Cannot update state with empty referral`
    if (!referral.id && !referral._id) throw `Cannot update state with invalid referral`
    if (!referral.id) console.warn('Referral has no id', referral)
    if (!referral._id) {
      console.warn('Referral has no _id', referral)
      referral = ensureCid(referral, state.referrals)
      console.warn(`Referral has been assigned _id ${referral._id}`)
    }

    const index = state.referrals.findIndex((r) => r._id == referral._id || r.id == referral.id)
    if (index < 0) {
      state.referrals.push(referral)
    } else {
      var exisiting = state.referrals[index]

      if (!!exisiting.updated_at && exisiting.client_version && exisiting.client_version >= referral.client_version) {
        if (exisiting.updated_at != referral.updated_at) {
          try {
            const changes = compareReferral(exisiting, referral)
            const changedFieldsCount = Object.keys(changes)?.length
            if (!changedFieldsCount) {
              return //nothing significant changed
            }

            //we have changes, server is likely newer but let's make sure client version
            if (exisiting.client_version > referral.client_version && changedFieldsCount > 1) {
              console.warn('Referral update out of sync', exisiting.updated_at, referral.updated_at, changes)
              return
            }
            const serverValues = Object.entries(changes).reduce(
              (acc, [field, values]) => ({ ...acc, [field]: values.server }),
              {}
            )
            commit('patchReferral', { id: referral._id, ...serverValues, client_version: exisiting.client_version })
          } catch (e) {
            console.error(e)
          }
        }
      }

      //if there are changes, lets ensure we have a client id if we dont have one
      if (!referral._id || referral._id == referral.id) {
        if (!isClientId(referral.id)) {
          console.warn('Referral had changes but doesnt have a client id. Check changes')
          referral.id = exisiting?.id
        }
      }
      commit('saveReferral', referral)
      // Vue.set(state.referrals, index, referral)
      // Vue.set(state.referrals[index], 'attachments', referral.attachments)
    }
  },
  async load({ commit, state, dispatch, rootState, getters }, refresh) {
    if (refresh) cache = {}
    if (!rootState?.auth?.token) {
      commit('setLoaded', true)
      return
    }
    const mKey = `referralGet${rootState.tour.activeTour || ''}${
      rootState.auth.user?.practice?.id || new Date().getTime()
    }`
    const fetchFunc = fetchReferrals(state, commit, dispatch, getters)
    let referrals = memoize(async () => await fetchFunc(), mKey)()
    return referrals
  },
  async loadAll({ commit, state, rootState, getters }) {
    if (!rootState?.auth?.user?.practice?.has_active_subscription) {
      commit('setLoadedAll', true)
      return
    }

    const mKey = `referralGet${rootState.tour.activeTour || ''}${
      rootState.auth.user?.practice?.id || new Date().getTime()
    }`

    //Get all referrals for premium users
    commit('setLoadingAll', true)
    try {
      const all = await memoize(async () => await getters.service.get('all'), mKey + 'all')()
      commit('setReferrals', mergeReferrals([...state.referrals, ...all], state.referrals))
      commit('filterReferrals', { filter: null })
      commit('setLoadedAll', true)
      commit('setLoadingAll', false)
    } catch (err) {
      commit('setError', err)
      console.error(err)
      captureException(err)
    }
  },
  reset({ commit, getters, state }, { preserveReferrals }) {
    cache = {}
    //preserve draft referrals, for oneslip, techinically referring_doctor_id could be set but should be ok
    const currentDrafts = state.referrals.filter((s) => !s.updated_at)
    commit('setError', null)
    if (!preserveReferrals) {
      commit('setReferrals', currentDrafts)
      commit('setTotalCount', 0)
      commit('setCurrentReferralId', currentDrafts?.[0]?.id)
    } else console.info('Reset is preserving referrals')
    commit('filterReferrals', { filter: null })
    commit('setLoaded', false)
    ReferralDemoApi && ReferralDemoApi.reset()
  },
  _save: ({ commit, getters, state, rootState, dispatch }, { referral, data }) => {
    referral.client_version = Date.now()
    referral.is_saving = true

    dispatch('_saveReferral', ensureCid(referral, state.referrals))
    if (!rootState.auth.token || rootState.auth.user?.is_guest || !rootState.auth.user?.practice?.is_setup_complete) {
      data = {
        ...data,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        location: { latitude: rootState.geo.location?.lat, longitude: rootState.geo.location?.lon },
        zip: rootState.geo.location?.zip,
        state: rootState.geo.location?.state,
        country_code: rootState.geo.location?.country_code,
      }
    }
    const postData = { ...prepReferralForSave(referral), ...data }
    return new Promise((resolve, reject) =>
      getters.service
        .save(postData, { anonymous: true })
        .then((s) => {
          // commit('changeReferralId', { oldId: referral.id, newId: s.id })
          const isNewServerId = referral._id != s.id
          if (isNewServerId) commit('addIdMapping', { clientId: referral._id, serverId: s.id })
          //we only want to overwrite the _id if the server has returned a new id
          // force_state_update if the referral was sent, so we can update the state with the sent_time and anything after
          let savedReferral = ensureCid(s, state.referrals, isNewServerId ? referral._id : null)
          dispatch('_saveReferral', savedReferral)

          commit('patchReferral', { id: savedReferral._id, updated_at: s.updated_at, is_saving: false })
          resolve(s)
        })
        .catch((e) => {
          console.error('Error saving referral', e)
          commit('patchReferral', { id: referral.id, is_saving: false })
          reject(e)
        })
    )
  },
  save: ({ dispatch }, referral) => {
    return dispatch('_save', { referral })
  },
  saveCurrentReferral: ({ state, dispatch, getters }) => {
    const referral = getters.get(state.currentReferralId)
    if (!referral) throw `No current referral for ${state.currentReferralId}`
    return dispatch('_save', { referral })
  },
  createNew({ state, commit, dispatch }, referral) {
    if (!referral) throw 'Cannot create new referral without referral'
    if (!referral._id) throw 'Cannot create new referral without referral _id'
    // const recentNew = state.referrals.find((r) => r._new)
    // if (recentNew) {
    //   //let's not create a new referral if there is already a new one
    //   commit('changeReferralId', { oldId: recentNew.id, newId: referral.id })
    //   commit('patchReferral', { id: recentNew.id, client_version: Date.now() })
    //   return
    // }
    dispatch('_saveReferral', ensureCid(referral, state.referrals))
  },
  updateAttachments: ({ commit, state, dispatch }, { referralId, attachments }) => {
    if (!referralId || !attachments?.length) throw `Cannot update state with invalid attachment for ${referralId}`

    const index = state.referrals.findIndex((r) => r._id == referralId || r.id == referralId)

    if (index < 0) throw `Cannot update attachment: Referral ${referralId} for attachment not found in state`

    const referral = state.referrals[index]
    referral.client_version = Date.now()
    dispatch('_saveReferral', referral)
    for (let attachment of attachments) commit('saveAttachment', { referralId: referral.id, attachment })
  },
  attach: ({ commit, state }, { referralId, attachment }) => {
    if (!referralId || !attachment) throw `Cannot update state with invalid attachment for ${referralId}`

    const index = state.referrals.findIndex((r) => r.id == referralId || r.id == referralId)

    if (index < 0) throw `Cannot attach attachment: Referral ${referralId} for attachment not found in state`

    const referral = state.referrals[index]
    referral.client_version = Date.now()
    dispatch('_saveReferral', referral)
    commit('saveAttachment', { referralId: referral.id, attachment })
  },
  deleteAttachment: ({ commit, state, dispatch }, { referralId, attachmentId }) => {
    if (!referralId || !attachmentId) throw `Cannot delete attachment with invalid payload`

    // const index = state.referrals.findIndex((r) => r.id == referralId || r.id == referralId)

    // if (index < 0) throw `Cannot delete attachment: Referral ${referralId} for attachment not found in state`

    // const referral = state.referrals[index]
    // referral.client_version = Date.now()
    // commit('saveReferral', referral)
    commit('deleteAttachment', { referralId, attachmentId })
    return new Promise((resolve, reject) => AttachmentApi.delete(attachmentId).then(resolve).catch(reject))
  },
  search: ({ commit, state }, term) => commit('filterReferrals', { filter: state.activeFilter, term }),
  filter: ({ commit, state }, filter) => commit('filterReferrals', { filter, term: state.searchTerm }),
  sort: ({ commit, state }, field) =>
    commit('setSort', {
      field,
      isAscending: state.sortField === field ? !state.sortAscending : true,
    }),
  onAuthChange: ({ dispatch }, p) => {
    if (p.isPracticeChanged) {
      dispatch('reset', { preserveReferrals: p.user.is_guest && p.isFreshLogin })
      if (p.type >= 0) dispatch('load')
    }
  },
  setPracticeContext: ({ commit, state, rootState }, practiceId) => {
    commit('setPracticeContext', practiceId || null)
    // const filter = (!practiceId || practiceId == rootState.auth.user.practice.id) ? 'Needs Action' : 'All';
    // commit('filterReferrals', { filter, term: state.searchTerm })
  },
  stateUpdate: async ({ commit, state, getters, dispatch }, { id, ts, cv, force }) => {
    const index = state.referrals.findIndex((r) => r.id == id || r._id == id)
    let exisitingCid = null
    if (index >= 0) {
      const existing = state.referrals[index]
      exisitingCid = existing.id
      if (
        // !force &&
        existing.client_version != null &&
        // existing.updated_at >= ts &&
        existing.client_version >= cv &&
        !existing.sent_time
      ) {
        // console.info('Ignore state notification', existing.client_version, cv)
        return
      }
    }
    let referral = await getters.service.get(id)
    dispatch('_saveReferral', ensureCid(referral, null, exisitingCid), force) //todo: force doesnt work because payload one arg, have saveReferralForce, or move onto referral
  },
  stateUpdateAttachment: async ({ commit, state, getters }, { id, ts, cv, force, attachment }) => {
    commit('saveAttachment', { referralId: id, attachment })
  },
  stateDeleteAttachment: async ({ commit, state, getters }, { id, ts, cv, force, attachment }) => {
    commit('deleteAttachment', { referralId: id, attachmentId: attachment.id })
  },
  delete: async ({ commit }, id) => {
    commit('deleteReferral', id)
    //todo:error handle
    if (isClientId(id)) id = state.clientIdMap?.[id]
    if (!id) return
    await ReferralApi.delete(id)
  },
  readNotes: async ({ getters, commit }, id) => {
    if (getters.unreadNotes(id).length) {
      commit('patchReferral', { id, last_read_note: moment().utc() })
      await ReferralApi.patch(`${id}/read-notes`, {}, { json: false })
    }
  },
  setIsSuccessModalShown: ({ commit }, isVisible) => {
    commit('setIsSuccessModalShown', isVisible)
  },
}

const getCurrentFilter = (state, isOutgoing) => {
  const key = (
    state.referrals.activeFilter ||
    'Needs Action' ||
    (state.auth.user?.practice?.specialty == 0 ? 'Sent' : 'Received')
  )
    .toLowerCase()
    .replace(/ /g, '_')
  const now = moment()
  return filters[key]({ state, now })
  return ((isOutgoing && filters[key + '_OUTGOING']) || filters[key])({ state })
}
const getFilter = (state, filter) => {
  const key = (filter || state.referrals.activeFilter).toLowerCase().replace(/ /g, '_')
  const now = moment()
  return filters[key]({ state, now })
}
const getters = {
  getReferralField: (state) => {
    if (!state.currentReferralId) return getField('')
    const idx = state.referrals?.findIndex((r) => r._id == state.currentReferralId || r.id == state.currentReferralId)

    return getField(state.referrals?.[idx] || '')
  },
  all: (state, getters, root, rootGetters) =>
    rootGetters['auth/isLoggedIn'] &&
    [...(state.results || state.referrals)]
      .filter(contextPracticeFilter(state))
      .filter(getCurrentFilter(root, true))
      .sort(sorter(root)),

  get: (state) => (id) => id && state.referrals.find((r) => r._id == id || r.id == id),

  allCount: (state) => state.referrals.length,

  service: (state, getters, root) => (root.tour.activeTour ? ReferralDemoApi : ReferralApi),

  //todo: need to put filters here, which uses rootState to dtermine filters for current user. reduce check each filter to see if it satisifies an object.
  filterCount: (state, getters, root, rootGetters) => (filter) =>
    (state.results || state.referrals || []).filter(contextPracticeFilter(state)).filter(getFilter(root, filter))
      .length,

  contactedWithNoUpdate: (state, getters, root) =>
    state.referrals.filter(
      (r) => r.is_incoming && r.patient_called_on && !r.snooze_until_to_time && !r.is_marked_scheduled
    ),

  unreadNotes: (state, getters, root, rootGetters) => (id) => {
    const r = getters.get(id)
    const notes = r.history.filter(
      (s) =>
        s.created_by.practice_id != root.auth.user.practice.id &&
        (!r.last_read_note || moment(s.created_at) > moment(r.last_read_note))
    )
    return notes
  },

  idMap: (state) => (id) => state.clientIdMap?.[id] || id,

  // draftReferral: (state) => state.referrals.find((r) => !r.updated_at),
  // currentReferral: (state) =>
  //   state.referrals.find((r) => r.id == state.currentReferralId || r._id == state.currentReferralId),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
