export function formatPhoneNumber(value) {
  // Remove all non-digit characters and limit to 10 digits
  const digits = (value || '').replace(/\D/g, '').slice(0, 10)

  // Format based on the length of the digits
  if (digits.length === 0) {
    return '' // Return empty for no input
  } else if (digits.length <= 3) {
    return `(${digits}`
  } else if (digits.length <= 6) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}`
  } else {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
  }
}

export const phoneDirective = {
  bind(el) {
    let isFormatting = false
    el.addEventListener('input', () => {
      if (isFormatting) return

      isFormatting = true
      const formattedValue = formatPhoneNumber(el.value)
      if (el.value !== formattedValue) {
        el.value = formattedValue
        el.dispatchEvent(new Event('input'))
      }
      isFormatting = false
    })
  },
}

//todo: consider using vue-the-mask if this doesn't hold up or more options are needed
