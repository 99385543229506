import store from '@/store'
import moment from 'moment'
export default class ReferaDummyClient {
  objects = {}
  definedProps = (obj) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => v !== undefined && v !== null))
  mockReferral = () => {
    const now = moment().toISOString()
    const user = {
      email: 'demo@refera.com',
      id: 1,
      first_name: 'Refera',
      last_name: 'Demo',
      is_doctor: true,
    }
    return {
      id: 1,
      patient_firstname: 'Jane',
      patient_middlename: null,
      patient_lastname: 'Doe',
      patient_phone: '1234567890',
      patient_dob: '1963-05-08',
      patient_email: 'jane.doe@example.com',
      guardian_name: null,
      availability: '',
      preferred_contact_method: 'any',
      practice: store.state.auth.user.practice,
      practice_id: store.state.auth.user.practice.id,
      referring_doctor: {
        email: 'drdemo@refera.com',
        id: 1,
        first_name: 'Doctor',
        last_name: 'Demo',
        is_doctor: true,
      },
      referring_doctor_id: 1,
      comments: '',
      reasons: ['Pain Evalutation'],
      radiographs_mailed: false,
      radiographs_patient: false,
      radiographs_panorex: false,
      teeth: ['UL'],
      type: null,
      cancelled_time: null,
      completed_time: null,
      history: [
        {
          id: 867,
          details: 'Referral Created',
          type: 'SYSTEM',
          created_by: user,
          created_at: now,
        },
      ],
      attachments: [],
      to_practice: store.getters['tour/mockPractice'],
      to_practice_id: store.getters['tour/mockPractice'].id,
      scheduled_time: null,
      doctor: 'Any (First Available)',
      sent_time: null,
      archived_from_time: null,
      archived_to_time: null,
      needs_action: true,
      snooze_until_from_time: null,
      snooze_until_to_time: null,
      patient_insurance_network: null,
      patient_insurance_membership: null,
      patient_insurance_subscriber: '',
      patient_insurance_dob: null,
      patient_insurance_network_secondary: null,
      patient_insurance_membership_secondary: null,
      patient_insurance_subscriber_secondary: '',
      patient_insurance_dob_secondary: null,
      insurance_information: 'noinsurance',
      requires_followup: false,
      scheduled_at_time: null,
      is_incoming: false,
      followup_completed_time: null,
      created_by: user,
      created_at: now,
      updated_by: user,
      updated_at: now,
      last_email_status_time: now,
      last_email_status: 'delivered',
      is_patient_opted_in: true,
    }
  }

  reset = () => {
    this.objects = {}
  }

  save(model, opt = {}) {
    if (!model.id) {
      model = { ...this.mockReferral(), ...this.definedProps(model) }
      model.id = 'tour-' + Object.keys(this.objects).length
    }
    model.created_by = store.state.auth.user
    model.updated_by = store.state.auth.user
    model.created_at = moment().toISOString()
    model.updated_at = moment().toISOString()
    this.objects[model.id] = model
    return Promise.resolve(model)
  }

  get(id, opt = {}) {
    return Promise.resolve(this.objects[id])
  }

  all(query = {}, opt = {}) {
    return Promise.resolve(Object.keys(this.objects).map((id) => this.objects[id]))
  }

  saveRelated(modelId, name, model, opt = {}) {
    return Promise.resolve(model)
  }
}
