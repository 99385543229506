import NpiService from '@/services/NpiService'
import Vue from 'vue'
import moment from 'moment'
const state = {
  suggested: [],
  isLoading: false,
  isLoaded: false,
  awaiter: null
}

const mutations = {
  setSuggested(state, suggested) {
    state.suggested = suggested
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  },
  add(state, practice) {
    Vue.set(state.suggested, practice.specialty, [practice, ...state.suggested[practice.specialty]])
  },
  remove(state, id) {
    const practice = state.suggested.flatMap(s => s).find(p => p.id == id)
    if (!practice?.id) return
    Vue.set(
      state.suggested,
      practice.specialty,
      state.suggested[practice.specialty].filter(p => p.id != id)
    )
  },
  save(state, practice) {
    if (!practice || !practice.id) throw `Cannot update state with invalid suggested practice`
    const index = state.suggested[practice.specialty].findIndex(u => u.id == practice.id)
    if (index === -1) state.practices.push(practice)
    else Vue.set(state.suggested[practice.specialty], index, practice)
  },
  removeTour(state) {
    Vue.set(
      state.suggested,
      1,
      state.suggested[1].filter(s => !s.is_example)
    )
  },
  setAwaiter(state, awaiter) {
    state.awaiter = awaiter
  }
}

const actions = {
  async load({ commit, state, rootState, dispatch }, { miles, lat, lon, refresh = false } = {}) {
    let awaiter = state.awaiter
    if (awaiter && !refresh) return awaiter

    commit('setLoading', true)

    try {
      if (!lat || !lon) {
        const user = rootState.auth.user
        lat = rootState.geo.location?.lat || (!user.is_guest && user.practice?.location?.latitude)
        lon = rootState.geo.location?.lon || (!user.is_guest && user.practice?.location?.longitude)
      }
      if (!lat || !lon) {
        console.warn('No location for suggested')
      } else {
        awaiter = NpiService.suggested({ miles, lat, lon })
        commit('setAwaiter', awaiter)
        let suggested = await awaiter
        commit('setSuggested', suggested)
      }
    } catch (err) {
      console.error(err)
      commit('setSuggested', [])
    }
    commit('setLoading', false)
    commit('setLoaded', true)
    return awaiter
  },
  reset({ commit }) {
    commit('setLoaded', false)
    commit('setSuggested', [])
    commit('setAwaiter', null)
  },
  add({ commit }, practice) {
    if (!practice || !practice.specialty) return
    commit('add', practice)
  },
  removeById({ commit }, id) {
    commit('remove', id)
  },
  onAuthChange: ({ dispatch }, { user, oldUser, isPracticeChanged, isNewUser }) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (isNewUser) dispatch('load')
    }
  }
}

const getters = {
  flat: state => state.suggested.flatMap(s => s),
  withNetwork: (state, getters, rootState, rootGetters) => [
    ...(rootGetters['practices/network'] || []).map(s => ({
      ...s,
      last_referral_timestamp: s.last_referral_time && moment(s.last_referral_time).valueOf()
    })),
    ...getters.flat.map(s => ({ ...s, isSuggested: true }))
  ]
  // .reduce((a, c) => {
  //   a[c.id] = c
  //   return a
  // }, {})
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
