import Vue from 'vue'
// import posthog from 'posthog-js'

export default {
  install: (vue, router, store) => {
    Vue.prototype.withPremium = (fn, orWhen = false, ...args) => {
      const isPremium = orWhen || store.getters['billing/isPremium']
      // try {
      //   posthog.capture('$premiumFeature', {
      //     practice: store.state.auth.user.practice,
      //     isPremium
      //   })
      // } catch {}
      if (isPremium) return fn?.apply?.(this, args) || true
      else {
        store.dispatch('settings/setIsPaymentModalVisible', true)
        try {
          fn?.preventDefault?.call()
        } catch (e) {}
        return false
      }
    }
  },
}
