import { PatientApi } from '@/services'
import moment from 'moment'
const dummyAppointments = num => {
  let start = Math.floor(num * -0.5)
  const mid = start * -1 + 2 //todo: not a great hack, but no need to make this dynamic.. should be based on num items being displayed
  const now = moment()
  const nowish = moment().add(30 - (moment().minute() % 30), 'minutes')
  return [...Array(num).keys()].map(i => {
    const isMiddle = mid === i
    const dt = start === 0 ? nowish : moment(nowish).add(start / 2, 'hours')
    const when = moment.duration(dt.diff(now)).asSeconds()
    start++
    return {
      id: i + 1,
      patient: isMiddle
        ? 'All your appointments'
        : i % 2 === 0
        ? 'Jane Doe'
        : 'John Smith',
      patient_id: i + 1,
      status: 'Fixed',
      datetime: dt.format(),
      when,
      abs: Math.abs(when),
      isUpsell: isMiddle
    }
  })
}
const state = {
  appointments: [],
  isLoading: false,
  isLoaded: false
}

const mutations = {
  addAppointment(state, appointment) {
    state.appointments = [...state.appointments, appointment]
  },
  setAppointments(state, appointments) {
    state.appointments = appointments
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  }
}

const actions = {
  async load({ commit, state, rootGetters, rootState }) {
    if(rootGetters['auth/type'] < 1) return;
    
    if (rootState.tour.activeTour || !rootGetters['auth/isIntegrationActive']) {
      commit('setAppointments', dummyAppointments(20))
      return
    }
    commit('setLoading', true)
    try {
      let appointments = await PatientApi.get('appointments-today')
      commit('setAppointments', appointments)
    } catch (err) {
      commit('setAppointments', [])
    }

    commit('setLoading', false)
    commit('setLoaded', true)
  },
  reset({ commit }) {
    commit('setLoaded', false)
    commit('setAppointments', [])
  },
  onAuthChange: ({ dispatch }, { isPracticeChanged, isNewUser, type}) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (type>1) dispatch('load')
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
