import PubNub from 'pubnub'

const getPubNubUuid = (id) => `uid-${id || Math.random()}${process.env.VUE_APP_CHANNEL_PREFIX}`
const createPubNubInstance = (id) => {
  const userId = getPubNubUuid(id)
  return new PubNub({
    userId,
    publishKey: process.env.VUE_APP_STATE_PUB,
    subscribeKey: process.env.VUE_APP_STATE_SUB,
    cipherKey: process.env.VUE_APP_STATE_CIPHER_KEY,
    ssl: true,
    // heartbeatInterval: 540, // requires presence
  })
}

const state = {
  pubnubStateInstance: null,
  listener: null,
  status: 'NOT_INITIALIZED',
  lastTimeToken: null,
  currentTimeToken: null,
  channel: '',
}

const mutations = {
  setStatus(state, { category, lastTimetoken, currentTimetoken }) {
    state.status = category
    state.lastTimeToken = lastTimetoken
    state.currentTimeToken = currentTimetoken
  },
  setListener(state, listener) {
    state.listener = listener
  },
  setChannel(state, channel) {
    state.channel = channel
  },
  setInstance(state, instance) {
    state.pubnubStateInstance = instance
  },
}

const actions = {
  load: ({ dispatch, commit, state, rootState }) => {
    if (!(rootState.auth.user && rootState.auth.user.id)) return
    const listener = {
      status: (e) => commit('setStatus', e),
      message: (data) => ((data && data.message) || []).forEach((m) => dispatch(m.type, m.payload, { root: true })),
    }
    commit('setInstance', createPubNubInstance(rootState.auth.user.id))
    commit('setListener', listener)
    state.pubnubStateInstance.addListener(listener)
    dispatch('subscribe')
  },
  subscribe: ({ state, rootState, commit }) => {
    const channel =
      'state_update_' +
      (rootState.auth.user.practice?.id || rootState.auth.user.id) +
      process.env.VUE_APP_CHANNEL_PREFIX
    commit('setChannel', [channel])
    state.pubnubStateInstance.setUserId(getPubNubUuid(rootState.auth.user.id))
    state.pubnubStateInstance.subscribe({ channels: [channel] })
  },
  onAuthChange: async ({ dispatch, state }, { isPracticeChanged, isNewUser }) => {
    if (isPracticeChanged) {
      //state.pubnubStateInstance.removeListener(state.listener);
      state.pubnubStateInstance?.unsubscribe({ channels: [state.channel] })
      if (!state.listener) dispatch('load')
      else if (isNewUser) dispatch('subscribe')
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
