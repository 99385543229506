<template>
  <div :class="className" />
</template>

<script>
export default {
  name: 'content-placeholders-img',
  data () {
    return {
      className: 'vue-content-placeholders-img',
    }
  },
}
</script>
